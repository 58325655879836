import { FC, ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

function fallbackRender() {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
    </div>
  );
}

const ErrorProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ErrorBoundary FallbackComponent={fallbackRender}>{children}</ErrorBoundary>
  );
};

export default ErrorProvider;
