import React from "react";
import AppProvider from "../components/AppProvider";

const PrivacyPolicy = () => {
  return (
    <div>
      <AppProvider>
        <h1>Privacy Policy</h1>
      </AppProvider>
    </div>
  );
};

export default PrivacyPolicy;
