// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { useFetch } from "use-http";
// import { Button, Spinner, Table, Alert, Row, Col } from "react-bootstrap";
// import AppProvider from "../components/AppProvider";

// const Attendance = () => {
//   const { lectureId } = useParams();
//   const { get, post } = useFetch("/lecture");
//   const [lectureData, setLectureData] = useState<any>(null);
//   const [students, setStudents] = useState<any[]>([]);
//   const [attendanceData, setAttendanceData] = useState<any[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [totalPresent, setTotalPresent] = useState<number>(0);
//   const [totalAbsent, setTotalAbsent] = useState<number>(0);
//   const [attendanceSubmitted, setAttendanceSubmitted] =
//     useState<boolean>(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         const result = await get(`/${lectureId}`);
//         if (result.success) {
//           setLectureData(result.data);
//           setStudents(
//             result.data.attendanceList.map((student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//             }))
//           );
//           const initialAttendance = result.data.attendanceList.map(
//             (student: any) => ({
//               name: student.name._id,
//               isPresent: true,
//             })
//           );
//           setAttendanceData(initialAttendance);
//         } else {
//           // setError(result.message || "Failed to fetch lecture details.");
//         }
//       } catch (error) {
//         // setError("An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [get, lectureId]);

//   const handleSubmit = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const result = await post("/attendance", {
//         lecture: lectureId,
//         studentAttendance: attendanceData.map(({ name, isPresent }) => ({
//           name,
//           isPresent,
//         })),
//       });
//       if (result.success) {
//         setTotalPresent(
//           attendanceData.filter((student) => student.isPresent).length
//         );
//         setTotalAbsent(
//           attendanceData.filter((student) => !student.isPresent).length
//         );
//         setAttendanceSubmitted(true);
//       } else {
//         // setError(result.message || "Failed to submit attendance.");
//       }
//     } catch (error) {
//       // setError("An error occurred while submitting attendance.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
//     setAttendanceData((prevData) =>
//       prevData.map((student) =>
//         student.name === studentId ? { ...student, isPresent } : student
//       )
//     );
//     setAttendanceSubmitted(false);
//   };

//   return (
//     <div>
//       <AppProvider>
//         <div className="container">
//           {loading ? (
//             <Spinner animation="border" />
//           ) : error ? (
//             <Alert variant="danger">{error}</Alert>
//           ) : lectureData ? (
//             <>
//               <h2 className="mt-4">Lecture Details</h2>
//               <Table striped bordered hover className="details-table">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <strong>Subject</strong>
//                     </td>
//                     <td>{lectureData.subject.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Standard</strong>
//                     </td>
//                     <td>{lectureData.standard.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Branch</strong>
//                     </td>
//                     <td>{lectureData.branch.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Batch</strong>
//                     </td>
//                     <td>{lectureData.batch.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>From Time</strong>
//                     </td>
//                     <td>{lectureData.fromTime}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>To Time</strong>
//                     </td>
//                     <td>{lectureData.toTime}</td>
//                   </tr>
//                 </tbody>
//               </Table>

//               <h2 className="mt-4">Attendance List</h2>
//               <Table striped bordered hover className="attendance-table">
//                 <thead>
//                   <tr>
//                     <th>Name</th>
//                     <th>Attendance</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {students.map((student) => (
//                     <tr key={student.id}>
//                       <td>{student.name}</td>
//                       <td>
//                         <div className="form-check form-check-inline">
//                           <input
//                             className="form-check-input"
//                             type="radio"
//                             name={`attendance-${student.id}`}
//                             checked={
//                               attendanceData.find((x) => x.name === student.id)
//                                 ?.isPresent
//                             }
//                             onChange={() =>
//                               handleAttendanceChange(student.id, true)
//                             }
//                           />
//                           <label className="form-check-label">Present</label>
//                         </div>
//                         <div className="form-check form-check-inline">
//                           <input
//                             className="form-check-input"
//                             type="radio"
//                             name={`attendance-${student.id}`}
//                             checked={
//                               !attendanceData.find((x) => x.name === student.id)
//                                 ?.isPresent
//                             }
//                             onChange={() =>
//                               handleAttendanceChange(student.id, false)
//                             }
//                           />
//                           <label className="form-check-label">Absent</label>
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>

//               <Row className="mt-4">
//                 <Col>
//                   <Button onClick={handleSubmit}>Submit</Button>
//                 </Col>
//               </Row>

//               {attendanceSubmitted && (
//                 <Table striped bordered hover className="summary-table mt-4">
//                   <thead>
//                     <tr>
//                       <th>Status</th>
//                       <th>Count</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>Total Students</td>
//                       <td>{attendanceData.length}</td>
//                     </tr>
//                     <tr>
//                       <td>Present</td>
//                       <td>{totalPresent}</td>
//                     </tr>
//                     <tr>
//                       <td>Absent</td>
//                       <td>{totalAbsent}</td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               )}
//             </>
//           ) : null}
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

// export default Attendance;

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { useFetch } from "use-http";
// import { Button, Spinner, Table, Alert, Row, Col } from "react-bootstrap";
// import AppProvider from "../components/AppProvider";

// const Attendance = () => {
//   const { lectureId } = useParams();
//   const { get, post } = useFetch("/lecture");
//   const [lectureData, setLectureData] = useState<any>(null);
//   const [students, setStudents] = useState<any[]>([]);
//   const [attendanceData, setAttendanceData] = useState<any[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [totalPresent, setTotalPresent] = useState<number>(0);
//   const [totalAbsent, setTotalAbsent] = useState<number>(0);
//   const [attendanceSubmitted, setAttendanceSubmitted] =
//     useState<boolean>(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         const result = await get(`/${lectureId}`);
//         if (result.success) {
//           setLectureData(result.data);
//           const fetchedStudents = result.data.attendanceList.map(
//             (student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//             })
//           );
//           setStudents(fetchedStudents);
//           // Only reset attendanceData if attendance hasn't been submitted
//           if (!attendanceSubmitted) {
//             const initialAttendance = result.data.attendanceList.map(
//               (student: any) => ({
//                 _id: student.name._id,
//                 name: student.name._id,
//                 isPresent: true,
//               })
//             );
//             setAttendanceData(initialAttendance);
//           }
//         } else {
//           // setError(result.message || "Failed to fetch lecture details.");
//         }
//       } catch (error) {
//         // setError("An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [get, lectureId, attendanceSubmitted]);

//   const handleSubmit = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const result = await post("/attendance", {
//         lecture: lectureId,
//         studentAttendance: attendanceData.map(({ _id, name, isPresent }) => ({
//           _id,
//           name,
//           isPresent,
//         })),
//       });
//       if (result.success) {
//         const presentCount = attendanceData.filter(
//           (student) => student.isPresent
//         ).length;
//         const absentCount = attendanceData.filter(
//           (student) => !student.isPresent
//         ).length;
//         setTotalPresent(presentCount);
//         setTotalAbsent(absentCount);
//         setAttendanceSubmitted(true); // Mark attendance as submitted
//         // setTotalPresent(
//         //   attendanceData.filter((student) => student.isPresent).length
//         // );
//         // setTotalAbsent(
//         //   attendanceData.filter((student) => !student.isPresent).length
//         // );

//         // setAttendanceSubmitted(true); // Mark attendance as submitted
//       } else {
//         // setError(result.message || "Failed to submit attendance.");
//       }
//     } catch (error) {
//       //   setError("An error occurred while submitting attendance.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
//     if (!attendanceSubmitted) {
//       setAttendanceData((prevData) =>
//         prevData.map((student) =>
//           student.name === studentId ? { ...student, isPresent } : student
//         )
//       );
//     } // Reset submitted state when attendance data changes
//   };

//   return (
//     <div>
//       <AppProvider>
//         <div className="container">
//           {loading ? (
//             <Spinner animation="border" />
//           ) : error ? (
//             <Alert variant="danger">{error}</Alert>
//           ) : lectureData ? (
//             <>
//               <h2 className="mt-4">Lecture Details</h2>
//               <Table striped bordered hover className="details-table">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <strong>Subject</strong>
//                     </td>
//                     <td>{lectureData.subject.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Standard</strong>
//                     </td>
//                     <td>{lectureData.standard.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Branch</strong>
//                     </td>
//                     <td>{lectureData.branch.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Batch</strong>
//                     </td>
//                     <td>{lectureData.batch.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>From Time</strong>
//                     </td>
//                     <td>{lectureData.fromTime}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>To Time</strong>
//                     </td>
//                     <td>{lectureData.toTime}</td>
//                   </tr>
//                 </tbody>
//               </Table>

//               <Row className="mt-4">
//                 <Col>
//                   <Button onClick={handleSubmit}>Submit</Button>
//                 </Col>
//               </Row>

//               {attendanceSubmitted && (
//                 <>
//                   <h2 className="mt-4">Attendance Summary</h2>
//                   <Table striped bordered hover className="summary-table mt-4">
//                     <thead>
//                       <tr>
//                         <th>Status</th>
//                         <th>Count</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr>
//                         <td>Total Students</td>
//                         <td>{attendanceData.length}</td>
//                       </tr>
//                       <tr>
//                         <td>Present</td>
//                         <td>{totalPresent}</td>
//                       </tr>
//                       <tr>
//                         <td>Absent</td>
//                         <td>{totalAbsent}</td>
//                       </tr>
//                     </tbody>
//                   </Table>
//                 </>
//               )}
//             </>
//           ) : null}
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

// export default Attendance;

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { useFetch } from "use-http";
// import { Button, Spinner, Table, Alert, Row, Col, Card } from "react-bootstrap";
// import AppProvider from "../components/AppProvider";

// const Attendance = () => {
//   const { lectureId } = useParams();
//   const { get, post } = useFetch("/lecture");
//   const [lectureData, setLectureData] = useState<any>(null);
//   const [students, setStudents] = useState<any[]>([]);
//   const [attendanceData, setAttendanceData] = useState<any[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [totalPresent, setTotalPresent] = useState<number>(0);
//   const [totalAbsent, setTotalAbsent] = useState<number>(0);
//   const [attendanceSubmitted, setAttendanceSubmitted] =
//     useState<boolean>(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         const result = await get(`/${lectureId}`);
//         if (result.success) {
//           setLectureData(result.data);
//           const fetchedStudents = result.data.attendanceList.map(
//             (student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//             })
//           );
//           setStudents(fetchedStudents);
//           // Only reset attendanceData if attendance hasn't been submitted
//           if (!attendanceSubmitted) {
//             const initialAttendance = result.data.attendanceList.map(
//               (student: any) => ({
//                 _id: student.name._id,
//                 name: student.name._id,
//                 isPresent: true,
//               })
//             );
//             setAttendanceData(initialAttendance);
//           }
//         } else {
//           setError(result.message || "Failed to fetch lecture details.");
//         }
//       } catch (error) {
//         // setError("An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, [get, lectureId, attendanceSubmitted]);

//   const handleSubmit = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const result = await post("/attendance", {
//         lecture: lectureId,
//         studentAttendance: attendanceData.map(({ _id, name, isPresent }) => ({
//           _id,
//           name,
//           isPresent,
//         })),
//       });
//       if (result.success) {
//         const presentCount = attendanceData.filter(
//           (student) => student.isPresent
//         ).length;
//         const absentCount = attendanceData.filter(
//           (student) => !student.isPresent
//         ).length;
//         setTotalPresent(presentCount);
//         setTotalAbsent(absentCount);
//         setAttendanceSubmitted(true); // Mark attendance as submitted
//       } else {
//         setError(result.message || "Failed to submit attendance.");
//       }
//     } catch (error) {
//       // setError("An error occurred while submitting attendance.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
//     if (!attendanceSubmitted) {
//       setAttendanceData((prevData) =>
//         prevData.map((student) =>
//           student.name === studentId ? { ...student, isPresent } : student
//         )
//       );
//     } // Reset submitted state when attendance data changes
//   };

//   return (
//     <div>
//       <AppProvider>
//         <h5 style={{ textAlign: "center", marginTop: "10px" }}>
//           Attendance Report
//         </h5>
//         <div className="container">
//           {loading ? (
//             <Spinner animation="border" />
//           ) : error ? (
//             <Alert variant="danger">{error}</Alert>
//           ) : lectureData ? (
//             <>
//               <Card>
//                 <h6
//                   className="mt-4"
//                   style={{ textAlign: "center", padding: "5px" }}
//                 >
//                   Lecture Details
//                 </h6>
//                 <Table striped bordered hover className="details-table">
//                   <tbody>
//                     <tr>
//                       <td>
//                         <strong>Subject</strong>
//                       </td>
//                       <td>{lectureData.subject.name}</td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <strong>Standard</strong>
//                       </td>
//                       <td>{lectureData.standard.name}</td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <strong>Branch</strong>
//                       </td>
//                       <td>{lectureData.branch.name}</td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <strong>Batch</strong>
//                       </td>
//                       <td>{lectureData.batch.name}</td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <strong>From Time</strong>
//                       </td>
//                       <td>{lectureData.fromTime}</td>
//                     </tr>
//                     <tr>
//                       <td>
//                         <strong>To Time</strong>
//                       </td>
//                       <td>{lectureData.toTime}</td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               </Card>

//               <Card style={{ marginTop: "9px" }}>
//                 {!attendanceSubmitted ? (
//                   <>
//                     <h6 className="mt-4" style={{ textAlign: "center" }}>
//                       Attendance List
//                     </h6>
//                     <Table striped bordered hover className="attendance-table">
//                       <thead>
//                         <tr>
//                           <th>Name</th>
//                           <th>Attendance</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {students.map((student) => (
//                           <tr key={student.id}>
//                             <td>{student.name}</td>
//                             <td>
//                               <div className="form-check form-check-inline">
//                                 <input
//                                   className="form-check-input"
//                                   type="radio"
//                                   name={`attendance-${student.id}`}
//                                   checked={
//                                     attendanceData.find(
//                                       (x) => x.name === student.id
//                                     )?.isPresent
//                                   }
//                                   onChange={() =>
//                                     handleAttendanceChange(student.id, true)
//                                   }
//                                 />
//                                 <label className="form-check-label">
//                                   Present
//                                 </label>
//                               </div>
//                               <div className="form-check form-check-inline">
//                                 <input
//                                   className="form-check-input"
//                                   type="radio"
//                                   name={`attendance-${student.id}`}
//                                   checked={
//                                     !attendanceData.find(
//                                       (x) => x.name === student.id
//                                     )?.isPresent
//                                   }
//                                   onChange={() =>
//                                     handleAttendanceChange(student.id, false)
//                                   }
//                                 />
//                                 <label className="form-check-label">
//                                   Absent
//                                 </label>
//                               </div>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>

//                     <Row className="mt-4">
//                       <Col>
//                         <Button onClick={handleSubmit}>Submit</Button>
//                       </Col>
//                     </Row>
//                   </>
//                 ) : (
//                   <>
//                     <h6 className="mt-4" style={{ textAlign: "center" }}>
//                       Attendance Summary
//                     </h6>
//                     <Table
//                       striped
//                       bordered
//                       hover
//                       className="summary-table mt-4"
//                     >
//                       <thead>
//                         <tr>
//                           <th>Status</th>
//                           <th>Count</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         <tr>
//                           <td>Total Students</td>
//                           <td>{attendanceData.length}</td>
//                         </tr>
//                         <tr>
//                           <td>Present</td>
//                           <td>{totalPresent}</td>
//                         </tr>
//                         <tr>
//                           <td>Absent</td>
//                           <td>{totalAbsent}</td>
//                         </tr>
//                       </tbody>
//                     </Table>
//                   </>
//                 )}
//               </Card>
//             </>
//           ) : null}
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

// export default Attendance;

// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { useFetch } from "use-http";
// import { Button, Spinner, Table, Alert, Row, Col } from "react-bootstrap";
// import AppProvider from "../components/AppProvider";

// interface Student {
//   id: string;
//   name: string;
//   isPresent: boolean;
// }

// const Attendance = () => {
//   const { lectureId } = useParams();
//   const { get, post } = useFetch("/lecture");
//   const [lectureData, setLectureData] = useState<any>(null);
//   const [students, setStudents] = useState<any[]>([]);
//   const [attendanceData, setAttendanceData] = useState<any[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [totalPresent, setTotalPresent] = useState<number>(0);
//   const [totalAbsent, setTotalAbsent] = useState<number>(0);
//   const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
//   const [showPresentData, setShowPresentData] = useState<boolean>(false);
//   const [showAbsentData, setShowAbsentData] = useState<boolean>(false);
//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         const result = await get(`/${lectureId}`);
//         if (result.success) {
//           setLectureData(result.data);
//           setStudents(
//             result.data.attendanceList.map((student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//             }))
//           );
//           const initialAttendance = result.data.attendanceList.map(
//             (student: any) => ({
//               id: student.name._id,
//               name: student.name._id,
//               // isPresent: true,
//               isPresent: student.isPresent,
//             })
//           );
//           setAttendanceData(initialAttendance);
//           setTotalPresent(
//             initialAttendance.filter((student: Student) => student.isPresent)
//               .length
//           );
//           setTotalAbsent(
//             initialAttendance.filter((student: Student) => !student.isPresent)
//               .length
//           );
//         } else {
//           // setError(result.message || "Failed to fetch lecture details.");
//         }
//       } catch (error) {
//         // setError("An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [get, lectureId]);

//   const handleSubmit = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const result = await post("/attendance", {
//         lecture: lectureId,
//         studentAttendance: attendanceData.map(({ id, isPresent }) => ({
//           name: id,
//           isPresent,
//         })),
//       });
//       if (result.success) {
//         setIsSubmitted(true);
//         // Re-fetch the updated attendance data from the server
//         const updatedResult = await get(`/${lectureId}`);
//         if (updatedResult.success) {
//           const updatedAttendance = updatedResult.data.attendanceList.map(
//             (student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//               isPresent: student.isPresent,
//             })
//           );
//           setAttendanceData(updatedAttendance);
//           setTotalPresent(
//             updatedAttendance.filter((student: Student) => student.isPresent)
//               .length
//           );
//           setTotalAbsent(
//             updatedAttendance.filter((student: Student) => !student.isPresent)
//               .length
//           );
//         } else {
//           setError(
//             updatedResult.message || "Failed to fetch updated attendance data."
//           );
//         }
//       } else {
//         setError(result.message || "Failed to submit attendance.");
//       }
//     } catch (error) {
//       setError("An error occurred while submitting attendance.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
//     setAttendanceData((prevData) =>
//       prevData.map((student) =>
//         student.name === studentId ? { ...student, isPresent } : student
//       )
//     );
//   };
//   const handleShowPresentData = () => {
//     setShowPresentData(true);
//     setShowAbsentData(false);
//   };

//   const handleShowAbsentData = () => {
//     setShowPresentData(false);
//     setShowAbsentData(true);
//   };

//   return (
//     <div>
//       <AppProvider>
//         <div className="container">
//           {loading ? (
//             <Spinner animation="border" />
//           ) : error ? (
//             <Alert variant="danger">{error}</Alert>
//           ) : lectureData ? (
//             <>
//               <h2 className="mt-4">Lecture Details</h2>
//               <Table striped bordered hover className="details-table">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <strong>Subject</strong>
//                     </td>
//                     <td>{lectureData.subject.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Standard</strong>
//                     </td>
//                     <td>{lectureData.standard.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Branch</strong>
//                     </td>
//                     <td>{lectureData.branch.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Batch</strong>
//                     </td>
//                     <td>{lectureData.batch.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>From Time</strong>
//                     </td>
//                     <td>{lectureData.fromTime}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>To Time</strong>
//                     </td>
//                     <td>{lectureData.toTime}</td>
//                   </tr>
//                 </tbody>
//               </Table>

//               <h2 className="mt-4">Attendance List</h2>
//               <Table striped bordered hover className="attendance-table">
//                 <thead>
//                   <tr>
//                     <th>Name</th>
//                     <th>Attendance</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {students.map((student) => (
//                     <tr key={student.id}>
//                       <td>{student.name}</td>
//                       <td>
//                         <div className="form-check form-check-inline">
//                           <input
//                             className="form-check-input"
//                             type="radio"
//                             name={`attendance-${student.id}`}
//                             checked={
//                               attendanceData.find((x) => x.name === student.id)
//                                 ?.isPresent
//                             }
//                             onChange={() =>
//                               handleAttendanceChange(student.id, true)
//                             }
//                           />
//                           <label className="form-check-label">Present</label>
//                         </div>
//                         <div className="form-check form-check-inline">
//                           <input
//                             className="form-check-input"
//                             type="radio"
//                             name={`attendance-${student.id}`}
//                             checked={
//                               !attendanceData.find((x) => x.name === student.id)
//                                 ?.isPresent
//                             }
//                             onChange={() =>
//                               handleAttendanceChange(student.id, false)
//                             }
//                           />
//                           <label className="form-check-label">Absent</label>
//                         </div>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>

//               <Row className="mt-4">
//                 <Col>
//                   <Button onClick={handleSubmit}>Submit</Button>
//                 </Col>
//               </Row>

//               {isSubmitted && (
//                 <Table striped bordered hover className="summary-table mt-4">
//                   <thead>
//                     <tr>
//                       <th>Status</th>
//                       <th>Count</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td>Total Students</td>
//                       <td>{attendanceData.length}</td>
//                     </tr>
//                     <tr>
//                       <td>Present</td>
//                       <td>{totalPresent}</td>
//                     </tr>
//                     <tr>
//                       <td>Absent</td>
//                       <td>{totalAbsent}</td>
//                     </tr>
//                   </tbody>
//                 </Table>
//               )}
//             </>
//           ) : null}
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

// export default Attendance;

// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { useFetch } from "use-http";
// import { Button, Spinner, Table, Alert, Row, Col } from "react-bootstrap";
// import AppProvider from "../components/AppProvider";
// import moment from "moment";

// interface Student {
//   id: string;
//   name: string;
//   isPresent: boolean;
// }

// const Attendance = () => {
//   const { lectureId } = useParams();
//   const { get, post } = useFetch("/lecture");
//   const navigate = useNavigate();
//   const [lectureData, setLectureData] = useState<any>(null);
//   const [students, setStudents] = useState<any[]>([]);
//   const [attendanceData, setAttendanceData] = useState<Student[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [totalPresent, setTotalPresent] = useState<number>(0);
//   const [totalAbsent, setTotalAbsent] = useState<number>(0);
//   const [isSubmitted, setIsSubmitted] = useState<boolean>(false); // Track submission status

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         const result = await get(`/${lectureId}`);
//         if (result.success) {
//           setLectureData(result.data);
//           setStudents(
//             result.data.attendanceList.map((student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//             }))
//           );
//           const initialAttendance = result.data.attendanceList.map(
//             (student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//               isPresent: student.isPresent,
//             })
//           );
//           setAttendanceData(initialAttendance);
//           setTotalPresent(
//             initialAttendance.filter((student: Student) => student.isPresent)
//               .length
//           );
//           setTotalAbsent(
//             initialAttendance.filter((student: Student) => !student.isPresent)
//               .length
//           );
//           setIsSubmitted(
//             result.data.attendanceList.every((student: any) =>
//               student.hasOwnProperty("isPresent")
//             )
//           );
//         } else {
//           setError(result.message || "Failed to fetch lecture details.");
//         }
//       } catch (error) {
//         // setError("An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [get, lectureId]);

//   const handleSubmit = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const result = await post("/attendance", {
//         lecture: lectureId,
//         studentAttendance: attendanceData.map(({ id, isPresent }) => ({
//           name: id,
//           isPresent,
//         })),
//       });
//       if (result.success) {
//         setIsSubmitted(true); // Set submission status to true
//         const updatedResult = await get(`/${lectureId}`);
//         if (updatedResult.success) {
//           const updatedAttendance = updatedResult.data.attendanceList.map(
//             (student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//               isPresent: student.isPresent,
//             })
//           );
//           setAttendanceData(updatedAttendance);
//           setTotalPresent(
//             updatedAttendance.filter((student: Student) => student.isPresent)
//               .length
//           );
//           setTotalAbsent(
//             updatedAttendance.filter((student: Student) => !student.isPresent)
//               .length
//           );
//         } else {
//           setError(
//             updatedResult.message || "Failed to fetch updated attendance data."
//           );
//         }
//       } else {
//         setError(result.message || "Failed to submit attendance.");
//       }
//     } catch (error) {
//       // setError("An error occurred while submitting attendance.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
//     setAttendanceData((prevData) =>
//       prevData.map((student) =>
//         student.id === studentId ? { ...student, isPresent } : student
//       )
//     );
//   };

//   // const isAttendanceAllowed = (
//   //   fromTime: string,
//   //   toTime: string,
//   //   date: string
//   // ) => {
//   //   const now = moment();
//   //   const startTime = moment(
//   //     `${date} ${fromTime}`,
//   //     "YYYY-MM-DD HH:mm"
//   //   ).subtract(10, "minutes");
//   //   const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
//   //     10,
//   //     "minutes"
//   //   );
//   //   return now.isBetween(startTime, endTime);
//   // };
//   const isAttendanceAllowed = (
//     fromTime: string,
//     toTime: string,
//     date: string
//   ) => {
//     const now = moment();
//     const startTime = moment(`${date} ${fromTime}`, "YYYY-MM-DD HH:mm");
//     const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm");
//     return now.isBetween(startTime, endTime);
//   };

//   return (
//     <div>
//       <AppProvider>
//         <h5 style={{ textAlign: "center", marginTop: "10px" }}>
//           Today's Attendance Report
//         </h5>
//         <div className="container">
//           {loading ? (
//             <Spinner animation="border" />
//           ) : error ? (
//             <Alert variant="danger">{error}</Alert>
//           ) : lectureData ? (
//             <>
//               <h5 className="mt-4">Lecture Details</h5>
//               <Table striped bordered hover className="details-table">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <strong>Subject</strong>
//                     </td>
//                     <td>{lectureData.subject.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Standard</strong>
//                     </td>
//                     <td>{lectureData.standard.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Branch</strong>
//                     </td>
//                     <td>{lectureData.branch.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Batch</strong>
//                     </td>
//                     <td>{lectureData.batch.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>From Time</strong>
//                     </td>
//                     <td>{lectureData.fromTime}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>To Time</strong>
//                     </td>
//                     <td>{lectureData.toTime}</td>
//                   </tr>
//                 </tbody>
//               </Table>

//               {/* {isAttendanceAllowed(
//                 lectureData.fromTime,
//                 lectureData.toTime,
//                 lectureData.date
//               ) && !isSubmitted ? ( */}
//               <>
//                 <h5 className="mt-4">Attendance List</h5>
//                 <Table striped bordered hover className="attendance-table">
//                   <thead style={{ fontWeight: "bold" }}>
//                     <tr>
//                       <th>Name</th>
//                       <th>Attendance</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {students.map((student) => (
//                       <tr key={student.id}>
//                         <td>{student.name}</td>
//                         <td>
//                           <div className="form-check form-check-inline">
//                             <input
//                               className="form-check-input"
//                               type="radio"
//                               name={`attendance-${student.id}`}
//                               checked={
//                                 attendanceData.find((x) => x.id === student.id)
//                                   ?.isPresent
//                               }
//                               onChange={() =>
//                                 handleAttendanceChange(student.id, true)
//                               }
//                             />
//                             <label className="form-check-label">Present</label>
//                           </div>
//                           <div className="form-check form-check-inline">
//                             <input
//                               className="form-check-input"
//                               type="radio"
//                               name={`attendance-${student.id}`}
//                               checked={
//                                 !attendanceData.find((x) => x.id === student.id)
//                                   ?.isPresent
//                               }
//                               onChange={() =>
//                                 handleAttendanceChange(student.id, false)
//                               }
//                             />
//                             <label className="form-check-label">Absent</label>
//                           </div>
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </Table>
//               </>
//               {/* ) : ( */}
//               <Table striped bordered hover className="summary-table mt-4">
//                 <thead>
//                   <tr>
//                     <th>Status</th>
//                     <th>Count</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Total Students</td>
//                     <td>{attendanceData.length}</td>
//                   </tr>
//                   <tr>
//                     <td>Present</td>
//                     <td style={{ color: "green", fontWeight: "bolder" }}>
//                       {totalPresent}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Absent</td>
//                     <td style={{ color: "red", fontWeight: "bolder" }}>
//                       {totalAbsent}
//                     </td>
//                   </tr>
//                 </tbody>
//               </Table>
//               {/* )} */}

//               <Row className="mt-4">
//                 <Col>
//                   {isAttendanceAllowed(
//                     lectureData.fromTime,
//                     lectureData.toTime,
//                     lectureData.date
//                   ) &&
//                     !isSubmitted && (
//                       <Button onClick={handleSubmit}>Submit</Button>
//                     )}
//                 </Col>
//               </Row>
//             </>
//           ) : null}
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

// export default Attendance;

// import React, { useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import { useFetch } from "use-http";
// import { Button, Spinner, Table, Alert, Row, Col } from "react-bootstrap";
// import AppProvider from "../components/AppProvider";
// import moment from "moment";

// interface Student {
//   id: string;
//   name: string;
//   isPresent: boolean;
// }

// const Attendance = () => {
//   const { lectureId } = useParams();
//   const { get, post } = useFetch("/lecture");
//   const navigate = useNavigate();
//   const [lectureData, setLectureData] = useState<any>(null);
//   const [students, setStudents] = useState<any[]>([]);
//   const [attendanceData, setAttendanceData] = useState<Student[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);
//   const [totalPresent, setTotalPresent] = useState<number>(0);
//   const [totalAbsent, setTotalAbsent] = useState<number>(0);
//   const [isSubmitted, setIsSubmitted] = useState<boolean>(false); // Track submission status

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);
//       try {
//         const result = await get(`/${lectureId}`);
//         if (result.success) {
//           setLectureData(result.data);
//           setStudents(
//             result.data.attendanceList.map((student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//             }))
//           );
//           const initialAttendance = result.data.attendanceList.map(
//             (student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//               isPresent: student.isPresent,
//             })
//           );
//           setAttendanceData(initialAttendance);
//           setTotalPresent(
//             initialAttendance.filter((student: Student) => student.isPresent)
//               .length
//           );
//           setTotalAbsent(
//             initialAttendance.filter((student: Student) => !student.isPresent)
//               .length
//           );
//           setIsSubmitted(
//             result.data.attendanceList.every((student: any) =>
//               student.hasOwnProperty("isPresent")
//             )
//           );
//         } else {
//           setError(result.message || "Failed to fetch lecture details.");
//         }
//       } catch (error) {
//         // setError("An error occurred while fetching data.");
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, [get, lectureId]);

//   const handleSubmit = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       const result = await post("/attendance", {
//         lecture: lectureId,
//         studentAttendance: attendanceData.map(({ id, isPresent }) => ({
//           name: id,
//           isPresent,
//         })),
//       });
//       if (result.success) {
//         setIsSubmitted(true); // Set submission status to true
//         const updatedResult = await get(`/${lectureId}`);
//         if (updatedResult.success) {
//           const updatedAttendance = updatedResult.data.attendanceList.map(
//             (student: any) => ({
//               id: student.name._id,
//               name: student.name.name,
//               isPresent: student.isPresent,
//             })
//           );
//           setAttendanceData(updatedAttendance);
//           setTotalPresent(
//             updatedAttendance.filter((student: Student) => student.isPresent)
//               .length
//           );
//           setTotalAbsent(
//             updatedAttendance.filter((student: Student) => !student.isPresent)
//               .length
//           );
//         } else {
//           setError(
//             updatedResult.message || "Failed to fetch updated attendance data."
//           );
//         }
//       } else {
//         setError(result.message || "Failed to submit attendance.");
//       }
//     } catch (error) {
//       // setError("An error occurred while submitting attendance.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
//     setAttendanceData((prevData) =>
//       prevData.map((student) =>
//         student.id === studentId ? { ...student, isPresent } : student
//       )
//     );
//   };

//   const isAttendanceAllowed = (
//     fromTime: string,
//     toTime: string,
//     date: string
//   ) => {
//     const now = moment();
//     const startTime = moment(`${date} ${fromTime}`, "YYYY-MM-DD HH:mm");
//     const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm");
//     return now.isBetween(startTime, endTime);
//   };

//   return (
//     <div>
//       <AppProvider>
//         <h5 style={{ textAlign: "center", marginTop: "10px" }}>
//           Today's Attendance Report
//         </h5>
//         <div className="container">
//           {loading ? (
//             <Spinner animation="border" />
//           ) : error ? (
//             <Alert variant="danger">{error}</Alert>
//           ) : lectureData ? (
//             <>
//               <h5 className="mt-4">Lecture Details</h5>
//               <Table striped bordered hover className="details-table">
//                 <tbody>
//                   <tr>
//                     <td>
//                       <strong>Subject</strong>
//                     </td>
//                     <td>{lectureData.subject.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Standard</strong>
//                     </td>
//                     <td>{lectureData.standard.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Branch</strong>
//                     </td>
//                     <td>{lectureData.branch.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>Batch</strong>
//                     </td>
//                     <td>{lectureData.batch.name}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>From Time</strong>
//                     </td>
//                     <td>{lectureData.fromTime}</td>
//                   </tr>
//                   <tr>
//                     <td>
//                       <strong>To Time</strong>
//                     </td>
//                     <td>{lectureData.toTime}</td>
//                   </tr>
//                 </tbody>
//               </Table>

//               {isAttendanceAllowed(
//                 lectureData.fromTime,
//                 lectureData.toTime,
//                 lectureData.date
//               ) &&
//                 !isSubmitted && (
//                   <>
//                     <h5 className="mt-4">Attendance List</h5>
//                     <Table striped bordered hover className="attendance-table">
//                       <thead style={{ fontWeight: "bold" }}>
//                         <tr>
//                           <th>Name</th>
//                           <th>Attendance</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {students.map((student) => (
//                           <tr key={student.id}>
//                             <td>{student.name}</td>
//                             <td>
//                               <div className="form-check form-check-inline">
//                                 <input
//                                   className="form-check-input"
//                                   type="radio"
//                                   name={`attendance-${student.id}`}
//                                   checked={
//                                     attendanceData.find(
//                                       (x) => x.id === student.id
//                                     )?.isPresent
//                                   }
//                                   onChange={() =>
//                                     handleAttendanceChange(student.id, true)
//                                   }
//                                 />
//                                 <label className="form-check-label">
//                                   Present
//                                 </label>
//                               </div>
//                               <div className="form-check form-check-inline">
//                                 <input
//                                   className="form-check-input"
//                                   type="radio"
//                                   name={`attendance-${student.id}`}
//                                   checked={
//                                     !attendanceData.find(
//                                       (x) => x.id === student.id
//                                     )?.isPresent
//                                   }
//                                   onChange={() =>
//                                     handleAttendanceChange(student.id, false)
//                                   }
//                                 />
//                                 <label className="form-check-label">
//                                   Absent
//                                 </label>
//                               </div>
//                             </td>
//                           </tr>
//                         ))}
//                       </tbody>
//                     </Table>

//                     <Row className="mt-4">
//                       <Col>
//                         <Button onClick={handleSubmit}>Submit</Button>
//                       </Col>
//                     </Row>
//                   </>
//                 )}

//               <Table striped bordered hover className="summary-table mt-4">
//                 <thead>
//                   <tr>
//                     <th>Status</th>
//                     <th>Count</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td>Total Students</td>
//                     <td>{attendanceData.length}</td>
//                   </tr>
//                   <tr>
//                     <td>Present</td>
//                     <td style={{ color: "green", fontWeight: "bolder" }}>
//                       {totalPresent}
//                     </td>
//                   </tr>
//                   <tr>
//                     <td>Absent</td>
//                     <td style={{ color: "red", fontWeight: "bolder" }}>
//                       {totalAbsent}
//                     </td>
//                   </tr>
//                 </tbody>
//               </Table>
//             </>
//           ) : null}
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

// export default Attendance;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "use-http";
import { Button, Spinner, Table, Alert, Row, Col } from "react-bootstrap";
import AppProvider from "../components/AppProvider";
import moment from "moment";

interface Student {
  id: string;
  name: string;
  isPresent: boolean;
}

const Attendance = () => {
  const { lectureId } = useParams();
  const { get, post } = useFetch("/lecture");
  const navigate = useNavigate();
  const [lectureData, setLectureData] = useState<any>(null);
  const [students, setStudents] = useState<any[]>([]);
  const [attendanceData, setAttendanceData] = useState<Student[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [totalPresent, setTotalPresent] = useState<number>(0);
  const [totalAbsent, setTotalAbsent] = useState<number>(0);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false); // Track submission status

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const result = await get(`/${lectureId}`);
        if (result.success) {
          setLectureData(result.data);
          setStudents(
            result.data.attendanceList.map((student: any) => ({
              id: student.name._id,
              name: student.name.name,
            }))
          );
          const initialAttendance = result.data.attendanceList.map(
            (student: any) => ({
              id: student.name._id,
              name: student.name.name,
              isPresent: student.isPresent,
            })
          );
          setAttendanceData(initialAttendance);
          setTotalPresent(
            initialAttendance.filter((student: Student) => student.isPresent)
              .length
          );
          setTotalAbsent(
            initialAttendance.filter((student: Student) => !student.isPresent)
              .length
          );
          setIsSubmitted(
            result.data.attendanceList.every((student: any) =>
              student.hasOwnProperty("isPresent")
            )
          );
        } else {
          setError(result.message || "Failed to fetch lecture details.");
        }
      } catch (error) {
        // setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [get, lectureId]);

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await post("/attendance", {
        lecture: lectureId,
        studentAttendance: attendanceData.map(({ id, isPresent }) => ({
          name: id,
          isPresent,
        })),
      });
      if (result.success) {
        setIsSubmitted(true); // Set submission status to true
        const updatedResult = await get(`/${lectureId}`);
        if (updatedResult.success) {
          const updatedAttendance = updatedResult.data.attendanceList.map(
            (student: any) => ({
              id: student.name._id,
              name: student.name.name,
              isPresent: student.isPresent,
            })
          );
          setAttendanceData(updatedAttendance);
          setTotalPresent(
            updatedAttendance.filter((student: Student) => student.isPresent)
              .length
          );
          setTotalAbsent(
            updatedAttendance.filter((student: Student) => !student.isPresent)
              .length
          );
        } else {
          setError(
            updatedResult.message || "Failed to fetch updated attendance data."
          );
        }
      } else {
        setError(result.message || "Failed to submit attendance.");
      }
    } catch (error) {
      // setError("An error occurred while submitting attendance.");
    } finally {
      setLoading(false);
    }
  };

  const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
    setAttendanceData((prevData) =>
      prevData.map((student) =>
        student.id === studentId ? { ...student, isPresent } : student
      )
    );
  };

  // const isAttendanceAllowed = (
  //   fromTime: string,
  //   toTime: string,
  //   date: string
  // ) => {
  //   const now = moment();
  //   const startTime = moment(`${date} ${fromTime}`, "YYYY-MM-DD HH:mm");
  //   const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm");
  //   return now.isBetween(startTime, endTime);
  // };

  const isAttendanceAllowed = (
    fromTime: string,
    toTime: string,
    date: string
  ) => {
    const now = moment();
    const startTime = moment(
      `${date} ${fromTime}`,
      "YYYY-MM-DD HH:mm"
    ).subtract(10, "minutes");
    const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
      10,
      "minutes"
    );
    return now.isBetween(startTime, endTime);
  };

  return (
    <div>
      <AppProvider>
        <h5 style={{ textAlign: "center", marginTop: "10px" }}>
          Today's Attendance Report
        </h5>
        <div className="container">
          {loading ? (
            <Spinner animation="border" />
          ) : error ? (
            <Alert variant="danger">{error}</Alert>
          ) : lectureData ? (
            <>
              <h5 className="mt-4">Lecture Details</h5>
              <Table striped bordered hover className="details-table">
                <tbody>
                  <tr>
                    <td>
                      <strong>Subject</strong>
                    </td>
                    <td>{lectureData.subject.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Standard</strong>
                    </td>
                    <td>{lectureData.standard.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Branch</strong>
                    </td>
                    <td>{lectureData.branch.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Batch</strong>
                    </td>
                    <td>{lectureData.batch.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>From Time</strong>
                    </td>
                    <td>{lectureData.fromTime}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>To Time</strong>
                    </td>
                    <td>{lectureData.toTime}</td>
                  </tr>
                </tbody>
              </Table>

              <Table bordered hover className="summary-table mt-4">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Students</td>
                    <td>{attendanceData.length}</td>
                  </tr>
                  <tr>
                    <td>Present</td>
                    <td style={{ color: "green", fontWeight: "bolder" }}>
                      {totalPresent}
                    </td>
                  </tr>
                  <tr>
                    <td>Absent</td>
                    <td style={{ color: "red", fontWeight: "bolder" }}>
                      {totalAbsent}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <h5 className="mt-4">Attendance List</h5>
              <Table bordered hover className="attendance-table">
                <thead style={{ fontWeight: "bold" }}>
                  <tr>
                    <th>Name</th>
                    <th>Attendance</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((student) => (
                    <tr key={student.id}>
                      <td>{student.name}</td>
                      <td>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`attendance-${student.id}`}
                            checked={
                              attendanceData.find((x) => x.id === student.id)
                                ?.isPresent
                            }
                            onChange={() =>
                              handleAttendanceChange(student.id, true)
                            }
                          />
                          <label className="form-check-label">Present</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`attendance-${student.id}`}
                            checked={
                              !attendanceData.find((x) => x.id === student.id)
                                ?.isPresent
                            }
                            onChange={() =>
                              handleAttendanceChange(student.id, false)
                            }
                          />
                          <label className="form-check-label">Absent</label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Row className="m-4">
                <Col>
                  {
                    isAttendanceAllowed(
                      lectureData.fromTime,
                      lectureData.toTime,
                      lectureData.date
                    ) && (
                      // !isSubmitted && (
                      <Button onClick={handleSubmit}>Submit</Button>
                    )
                    // )}
                  }
                </Col>
              </Row>
            </>
          ) : null}
        </div>
      </AppProvider>
    </div>
  );
};

export default Attendance;
