import { RouterProvider, createBrowserRouter } from "react-router-dom";
import AcademicYear from "../pages/AcademicYear";
// import Attendance from "../pages/Attendance";
import Batch from "../pages/Batch";
import Board from "../pages/Board";
import Branch from "../pages/Branch";
import BranchUser from "../pages/BranchUser";
import Dashboard from "../pages/Dashboard";
import Department from "../pages/Department";
import Designation from "../pages/Designation";
import ErrorPage from "../pages/ErrorPage";
import Gender from "../pages/Gender";
import Lecture from "../pages/Lecture";
import Login from "../pages/Login";
import Parent from "../pages/Parent";
import Password from "../pages/Password";
import Profile from "../pages/Profile";
import Relation from "../pages/Relation";
import School from "../pages/School";
import Shift from "../pages/Shift";
import Staff from "../pages/Staff";
import Standard from "../pages/Standard";
import Stream from "../pages/Stream";
import Student from "../pages/Student";
import Subject from "../pages/Subject";
import Teacher from "../pages/Teacher";
import Welcome from "../pages/Welcome";
import Year from "../pages/Year";
import PrivacyPolicy from "../pages/PrivacyPolicy";

import Class from "../pages/Class";
import Attendance from "../pages/Attendance";

import Test from "../pages/Test";
import TestAt from "../pages/TestAt";
import StudyMaterial from "../pages/StudyMaterial";
import Appointment from "../pages/Appointment";
// import Test1 from "../pages/Test1";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/branch",
    element: <Branch />,
  },
  {
    path: "/designation",
    element: <Designation />,
  },
  {
    path: "/department",
    element: <Department />,
  },
  {
    path: "/subject",
    element: <Subject />,
  },
  {
    path: "/standard",
    element: <Standard />,
  },
  {
    path: "/stream",
    element: <Stream />,
  },
  {
    path: "/year",
    element: <Year />,
  },
  {
    path: "/board",
    element: <Board />,
  },
  {
    path: "/school",
    element: <School />,
  },
  {
    path: "/relation",
    element: <Relation />,
  },
  {
    path: "/gender",
    element: <Gender />,
  },
  {
    path: "/academicyear",
    element: <AcademicYear />,
  },
  {
    path: "/batch",
    element: <Batch />,
  },
  {
    path: "/shift",
    element: <Shift />,
  },
  {
    path: "/student",
    element: <Student />,
  },
  {
    path: "/teacher",
    element: <Teacher />,
  },
  {
    path: "/lecture",
    element: <Lecture />,
  },
  // {
  //   path: "/attendance",
  //   element: <Attendance />,
  // },
  {
    path: "/parent",
    element: <Parent />,
  },
  {
    path: "/password",
    element: <Password />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/staff",
    element: <Staff />,
  },
  {
    path: "/branchUser",
    element: <BranchUser />,
  },
  {
    path: "/privacyPolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/class",
    element: <Class />,
  },
  {
    path: "/attendance/:lectureId",
    element: <Attendance />,
  },

  {
    path: "/test",
    element: <Test />,
  },
  {
    path: "/testAt/:testId",
    element: <TestAt />,
  },
  {
    path: "/studyMaterial",
    element: <StudyMaterial />,
  },
  {
    path: "/appointment",
    element: <Appointment />,
  },
  // {
  //   path: "/test1",
  //   element: <TestPage />,
  // },
]);

const RouteProvider = () => {
  return <RouterProvider router={router}></RouterProvider>;
};

export default RouteProvider;
