import { Formik } from "formik";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import {
  AddDesignationRoot,
  GetAllDesignationAPIRoot,
  GetTeacherByIdRoot,
} from "../utils/types";

const Teacher = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetAllDesignationAPIRoot>("/teacher");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);

      await get()
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [get]);

  const getData = async () => {
    setRefreshing(true);

    await get()
      .then((res) => {
        if (res) {
          if (res.success) {
            setRefreshing(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await getData();

    setRefreshing(false);
  };

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Teacher</h5>

                  {true && (
                    <Button onClick={() => navigate("/teacher?action=add")}>
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Is Active</th>
                      <th
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {response &&
                      response.ok &&
                      response.data &&
                      response?.data?.data.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>{item.name || ""}</td>
                            <td>{item.isActive ? "Yes" : "No"}</td>
                            <td
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",

                                  gap: "1rem",
                                }}
                              >
                                <Button
                                  onClick={() =>
                                    navigate(
                                      `/teacher?action=view&&id=${item._id}`
                                    )
                                  }
                                  variant="success"
                                >
                                  VIEW
                                </Button>
                                <Button
                                  onClick={() =>
                                    navigate(
                                      `/teacher?action=update&&id=${item._id}`
                                    )
                                  }
                                >
                                  UPDATE
                                </Button>
                                <Button
                                  variant="danger"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDelete(item._id);
                                      }
                                    });
                                  }}
                                >
                                  DELETE
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/teacher");

  const handleSubmit = async (values: { name: string; isActive: boolean }) => {
    await post(values)
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />

      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Teacher</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    username: Yup.string().required(),
                    password: Yup.string().required(),
                    name: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    name: "",
                    username: "",
                    password: "",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Username <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            isValid={touched.username && !errors.username}
                            isInvalid={!!errors.username}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Password <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            isValid={touched.password && !errors.password}
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post, loading } = useFetch<GetTeacherByIdRoot>("/teacher");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [refreshing, setRefreshing] = useState(false);
  const [initValues, setInitValues] = useState({
    name: "",
    username: "",
    address: "",
    email: "",
    contact: "",
    gender: "",
    dateOfBirth: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());
  const {
    get: getGender,
    response: genderResponse,
    loading: genderLoading,
    error: genderError,
  } = useFetch<GetAllDesignationAPIRoot>("/gender");

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      await getGender();

      if (parsed.id) {
        await get(`${parsed.id}`)
          .then((res) => {
            if (res.success) {
              console.log(res);

              if (res.data) {
                setInitValues({
                  isActive: res.data?.isActive || false,
                  name: res.data?.name || "",
                  username: res.data?.username || "",
                  address: res.data?.address || "",
                  email: res.data?.email || "",
                  contact: res.data?.contact || "",
                  gender: res.data?.gender?._id || "",
                  dateOfBirth: res.data?.dateOfBirth || "",
                });
              }

              setKey(Math.random());

              setRefreshing(false);
            } else {
              console.log(res);

              setRefreshing(false);
            }
          })
          .catch((err) => {
            console.log(err);

            setRefreshing(false);
          });
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    name: string;
    isActive: boolean;
    username: string;
    dateOfBirth: string;
    email: string;
    contact: string;
    gender: string;
    address: string;
  }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Teacher</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    username: Yup.string().required(),
                    address: Yup.string().required(),
                    email: Yup.string().required(),
                    contact: Yup.number().required(),
                    gender: Yup.string().required(),
                    dateOfBirth: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Username <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            isValid={touched.username && !errors.username}
                            isInvalid={!!errors.username}
                            disabled
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.username}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date of Birth{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="dateOfBirth"
                            value={values.dateOfBirth}
                            onChange={handleChange}
                            isValid={touched.dateOfBirth && !errors.dateOfBirth}
                            isInvalid={
                              !!touched.dateOfBirth && !!errors.dateOfBirth
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.dateOfBirth}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Email <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Contact <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="contact"
                            value={values.contact}
                            onChange={handleChange}
                            isValid={touched.contact && !errors.contact}
                            isInvalid={!!errors.contact}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contact}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Gender <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="gender"
                            onChange={handleChange}
                            value={values.gender}
                            isInvalid={!!touched.gender && !!errors.gender}
                          >
                            <option value={""}>select</option>

                            {genderResponse &&
                              genderResponse.data &&
                              genderResponse.data?.data &&
                              genderResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.gender}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Address <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            as={"textarea"}
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            isValid={touched.address && !errors.address}
                            isInvalid={!!errors.address}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.address}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get, response, loading, error } =
    useFetch<GetTeacherByIdRoot>("/teacher");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);
      if (parsed.id) {
        await get(`${parsed.id}`)
          .then((res) => {
            if (res) {
              if (res.success) {
                setRefreshing(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setRefreshing(false);
          });
      }
    })();
  }, [parsed.id, get]);

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>View Teacher</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  {response && response.ok && response.data && (
                    <Table bordered hover>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{response.data?.data.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Username</td>
                          <td>{response.data?.data.username || ""}</td>
                        </tr>

                        <tr>
                          <td>Date of Birth</td>
                          <td>{response.data?.data.dateOfBirth || ""}</td>
                        </tr>

                        <tr>
                          <td> Contact </td>
                          <td>{response.data?.data.contact || ""}</td>
                        </tr>

                        <tr>
                          <td> Email </td>
                          <td>{response.data?.data.email || ""}</td>
                        </tr>

                        <tr>
                          <td>Gender</td>
                          <td>{response.data?.data?.gender?.name || ""}</td>
                        </tr>

                        <tr>
                          <td> Address </td>
                          <td>{response.data?.data.address || ""}</td>
                        </tr>

                        <tr>
                          <td>Is Active</td>
                          <td>{response.data?.data.isActive ? "Yes" : "No"}</td>
                        </tr>

                        <tr>
                          <td>Created At</td>
                          <td>
                            {moment(response.data?.data.createdAt).format(
                              "DD-MM-YYYY H:mm A"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default Teacher;
