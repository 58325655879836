import { FC, ReactNode, Suspense } from "react";
import { CachePolicies, Provider } from "use-http";
import { API_URL } from "../utils/constants";
import CustomLoader from "./CustomLoader";

const HttpProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Provider
      url={API_URL}
      options={{
        cachePolicy: CachePolicies.NO_CACHE,
        suspense: true,
      }}
    >
      <Suspense fallback={<CustomLoader />}>{children}</Suspense>
    </Provider>
  );
};

export default HttpProvider;
