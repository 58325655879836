// export const API_URL = "https://connect.scpledu.com/api";
// export const API_URL = "http://localhost:4000/api";
export const API_URL = "https://connect.scpledu.com/api";
export const FILES_URL = "https://connect.scpledu.com/uploads";

//export const API_URL = "http://localhost:4000/api";
// export const API_URL =
// "https://pool-f-prototype-following.trycloudflare.com/api";
// export const API_URL = "https://connect.scpledu.com/api";
//export const API_URL = "http://localhost:4000/api";
