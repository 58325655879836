// import { useEffect, useState } from "react";
// import { Container, Dropdown, Form, Navbar } from "react-bootstrap";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useFetch } from "use-http";
// import { setAuth } from "../store/authReducer";
// import { setBranch } from "../store/branchReducer";
// import { useAppDispatch, useAppSelector } from "../store/hooks";
// import { GetAllDesignationAPIRoot } from "../utils/types";
// import { useDispatch, useSelector } from "react-redux";

// const CustomHeader = () => {
//   const navigate = useNavigate();
//   const dispatch = useAppDispatch();
//   const { pathname } = useLocation();
//   const _id = useAppSelector((state) => state.branchReducer._id);
//   // const teacher = useAppSelector((state) => state.authReducer._id);
//   const teacherId = useAppSelector((state) => state.authReducer._id);
//   const [key, setKey] = useState(Math.random());

//   const { get, response, loading, error } =
//     useFetch<GetAllDesignationAPIRoot>("/branch");

//   useEffect(() => {
//     const fetchData = async () => {
//       await get()
//         .then((res) => {
//           if (res) {
//             if (res.success) setKey(Math.random());
//           }
//         })
//         .catch((err) => console.log(err));
//     };

//     fetchData();
//   }, []);

//   // const handleBranchChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
//   //   const selectedBranchId = e.target.value;

//   //   dispatch(setBranch({ _id: selectedBranchId }));

//   //   // Refresh the page
//   //   // window.location.reload();
//   // };
//   // const handleBranchChange = (e) => {
//   //   const selectedBranchId = e.target.value;
//   //   dispatch(setBranch({ _id: selectedBranchId }));
//   // };

//   return (
//     <div>
//       <Navbar expand="lg" style={{ backgroundColor: "#82ba26", color: "#fff" }}>
//         <Container>
//           <Navbar.Brand>
//             <Link
//               to={"/dashboard"}
//               style={{
//                 textDecoration: "none",
//                 color: "white",
//                 fontSize: 28,
//                 fontWeight: "bold",
//               }}
//             >
//               SCPL
//             </Link>
//           </Navbar.Brand>

//           <div
//             className="justify-content-end"
//             style={{
//               display: "flex",
//               alignItems: "center",
//               gap: "1rem",
//             }}
//           >
//             <Navbar.Brand>
//               <Form.Group controlId="validationFormik02" key={key}>
//                 <Form.Select
//                   aria-label="Default select example"
//                   name="branch"
//                   size="sm"
//                   value={_id}
//                   onChange={(e) =>
//                     dispatch(
//                       setBranch({
//                         _id: e.target.value,
//                       })
//                     )
//                   }
//                   // onChange={handleBranchChange}
//                 >
//                   <option value={""}>select</option>

//                   {response &&
//                     response.data &&
//                     response.data?.data &&
//                     response.data?.data.map((item) => (
//                       <option value={item._id}>{item.name}</option>
//                     ))}
//                 </Form.Select>
//               </Form.Group>
//             </Navbar.Brand>

//             {/* <Navbar.Brand>
//               <i className="bi bi-bell-fill" style={{ color: "white" }}></i>
//             </Navbar.Brand> */}

//             <Navbar.Brand>
//               <Dropdown>
//                 <Dropdown.Toggle variant="light">
//                   <i className="bi bi-person-circle"></i>
//                   {/* {user ? user.name : <i className="bi bi-person-circle"></i>} */}
//                 </Dropdown.Toggle>

//                 <Dropdown.Menu align={"end"}>
//                   {/* {false && ( */}
//                   {/* <Dropdown.Item onClick={() => navigate("/profile")}>
//                     Profile
//                   </Dropdown.Item> */}
//                   <Dropdown.Item>
//                     {teacherId ? item.teacher._id : "Profile"}
//                   </Dropdown.Item>
//                   {/* )} */}
//                   {false && (
//                     <Dropdown.Item onClick={() => navigate("/password")}>
//                       Password
//                     </Dropdown.Item>
//                   )}
//                   <Dropdown.Item
//                     onClick={() => {
//                       dispatch(
//                         setAuth({
//                           _id: "",
//                           isAdmin: false,
//                           isAuth: false,
//                           isBranch: false,
//                           isCompany: false,
//                           isParent: false,
//                           isStudent: false,
//                           isTeacher: false,
//                         })
//                       );

//                       localStorage.setItem(
//                         "userAuth",
//                         JSON.stringify({
//                           isAuth: false,
//                         })
//                       );

//                       navigate("/");
//                     }}
//                   >
//                     Logout
//                   </Dropdown.Item>
//                 </Dropdown.Menu>
//               </Dropdown>
//             </Navbar.Brand>
//           </div>
//         </Container>
//       </Navbar>
//     </div>
//   );
// };

// export default CustomHeader;

import { useEffect, useState } from "react";
import { Container, Dropdown, Form, Navbar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "use-http";
import { setAuth } from "../store/authReducer";
import { setBranch } from "../store/branchReducer";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { GetAllDesignationAPIRoot } from "../utils/types";
import LOGO from "../assets/logo-removebg-preview.png";

const CustomHeader = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const _id = useAppSelector((state) => state.branchReducer._id);
  const teacherId = useAppSelector((state) => state.authReducer._id);
  // const teacherName = useAppSelector((state) => state.authReducer.name);
  // const teacherName = useAppSelector((state) => state.authReducer.user?.name); // Assuming user object contains teacher details
  const [key, setKey] = useState(Math.random());

  const { get, response, loading, error } =
    useFetch<GetAllDesignationAPIRoot>("/branch");

  useEffect(() => {
    const fetchData = async () => {
      await get()
        .then((res) => {
          if (res) {
            if (res.success) setKey(Math.random());
          }
        })
        .catch((err) => console.log(err));
    };

    fetchData();
  }, []);

  return (
    <div>
      <Navbar expand="lg" style={{ backgroundColor: "#008080", color: "#fff" }}>
        <Container>
          <Navbar.Brand>
            <Link
              to={"/dashboard"}
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: 28,
                fontWeight: "bold",
              }}
            >
              {/* SCPL */}
              <img src={LOGO} style={{ height: 50 }} alt="SCPL" />
            </Link>
          </Navbar.Brand>

          <div
            className="justify-content-end"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Navbar.Brand>
              <Form.Group controlId="validationFormik02" key={key}>
                <Form.Select
                  aria-label="Default select example"
                  name="branch"
                  size="sm"
                  value={_id}
                  onChange={(e) =>
                    dispatch(
                      setBranch({
                        _id: e.target.value,
                      })
                    )
                  }
                >
                  <option value={""}>select</option>

                  {response &&
                    response.data &&
                    response.data?.data &&
                    response.data?.data.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Navbar.Brand>

            <Navbar.Brand>
              <Dropdown>
                <Dropdown.Toggle variant="light">
                  <i className="bi bi-person-circle"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu align={"end"}>
                  <Dropdown.Item>
                    {/* {teacherId ? teacherId : "Profile"} */}
                    <b>Hello Teacher!!</b>
                  </Dropdown.Item>
                  {false && (
                    <Dropdown.Item onClick={() => navigate("/password")}>
                      Password
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      dispatch(
                        setAuth({
                          _id: "",
                          isAdmin: false,
                          isAuth: false,
                          isBranch: false,
                          isCompany: false,
                          isParent: false,
                          isStudent: false,
                          isTeacher: false,
                        })
                      );

                      localStorage.setItem(
                        "userAuth",
                        JSON.stringify({
                          isAuth: false,
                        })
                      );

                      navigate("/");
                    }}
                  >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Brand>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomHeader;
