import { Formik } from "formik";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import { API_URL, FILES_URL } from "../utils/constants";
import {
  AddDesignationRoot,
  FileUploadRoot,
  GetAllDesignationAPIRoot,
  GetStudyMaterialByIdRoot,
  StudyMaterialRoot,
} from "../utils/types";

const StudyMaterial = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<StudyMaterialRoot>("/studyMaterial");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);

      await get()
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [get]);

  const getData = async () => {
    setRefreshing(true);

    await get()
      .then((res) => {
        if (res) {
          if (res.success) {
            setRefreshing(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await getData();

    setRefreshing(false);
  };

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Study Material</h5>

                  {true && (
                    <Button
                      size="sm"
                      onClick={() => navigate("/studyMaterial?action=add")}
                    >
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Subject</th>
                      <th>Standard</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {response &&
                      response.ok &&
                      response.data &&
                      response?.data?.data.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>{item.name || ""}</td>
                            <td>{item?.subject?.name || ""}</td>
                            <td>{item?.standard?.name || ""}</td>
                            <td>{item.isActive ? "Yes" : "No"}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                }}
                              >
                                <a
                                  href={`${FILES_URL}/${item.doc}`}
                                  target="_blank"
                                >
                                  <Button size="sm" variant="success">
                                    VIEW
                                  </Button>
                                </a>
                                <Button
                                  size="sm"
                                  onClick={() =>
                                    navigate(
                                      `/studyMaterial?action=update&&id=${item._id}`
                                    )
                                  }
                                >
                                  UPDATE
                                </Button>
                                <Button
                                  size="sm"
                                  variant="danger"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDelete(item._id);
                                      }
                                    });
                                  }}
                                >
                                  DELETE
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const [filePath, setFilePath] = useState("");
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/studyMaterial");
  const { get: getSubject, response: subjectResponse } =
    useFetch<GetAllDesignationAPIRoot>("/subject");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const [isUploading, setIsUploading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getStandard();

        await getSubject();
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true);

      const { files } = e.target;
      const selectedFiles = files as FileList;

      let formData = new FormData();

      formData.append("file", selectedFiles?.[0]);

      await fetch(`${API_URL}/upload`, {
        body: formData,
        method: "POST",
      })
        .then(async (res) => {
          return await res.json();
        })
        .then((res: FileUploadRoot) => {
          if (res.success) {
            setIsUploading(false);
            setFilePath(res.data);
          }
        })
        .catch((err) => {
          alert(err);
          setIsUploading(false);
        });
    } catch (err) {
      alert(err);
      setIsUploading(false);
    }
  };

  const handleSubmit = async (values: {
    name: string;
    description: string;
    subject: string;
    standard: string;
    doc: string;
    isActive: boolean;
  }) => {
    if (filePath) {
      await post({ ...values, doc: filePath })
        .then(async (res) => {
          if (res.success) {
            navigate(-1);
          } else {
            alert(res.message);
          }
        })
        .catch((err) => {
          alert("Something went wrong");
          console.log(err);
        });
    } else {
      alert("file not available");
    }
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Study Material</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    description: Yup.string(),
                    subject: Yup.string().required(),
                    standard: Yup.string().required(),
                    doc: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    name: "",
                    description: "",
                    subject: "",
                    standard: "",
                    doc: "",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="8"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>Description </Form.Label>
                          <Form.Control
                            type="text"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            isInvalid={!!errors.description}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>Select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            File <span style={{ color: "red" }}>*</span>{" "}
                            {isUploading && <Spinner size="sm" />}
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="doc"
                            value={values.doc}
                            onChange={handleFileUpload}
                            isValid={touched.doc && !errors.doc}
                            isInvalid={!!errors.doc}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.doc}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const [filePath, setFilePath] = useState("");
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    name: "",
    description: "",
    subject: "",
    standard: "",
    doc: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());
  const { post, get } = useFetch<GetStudyMaterialByIdRoot>("/studyMaterial");
  const { get: getSubject, response: subjectResponse } =
    useFetch<GetAllDesignationAPIRoot>("/subject");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const [isUploading, setIsUploading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getStandard();

        await getSubject();

        await get(`${parsed.id}`)
          .then((res) => {
            console.log(res);

            if (res.success) {
              setInitValues({
                name: res.data.name || "",
                description: res.data.description || "",
                doc: "",
                standard: res.data.standard || "",
                subject: res.data.subject || "",
                isActive: res.data.isActive,
              });

              setKey(Math.random());
            }
          })
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true);

      const { files } = e.target;
      const selectedFiles = files as FileList;

      let formData = new FormData();

      formData.append("file", selectedFiles?.[0]);

      await fetch(`${API_URL}/upload`, {
        body: formData,
        method: "POST",
      })
        .then((res) => res.json())
        .then((res: FileUploadRoot) => {
          if (res.success) {
            setIsUploading(false);
            setFilePath(res.data);
          }
        })
        .catch((err) => {
          alert(err);
          setIsUploading(false);
        });
    } catch (err) {
      alert(err);
      setIsUploading(false);
    }
  };

  const handleSubmit = async (values: {
    name: string;
    description: string;
    subject: string;
    standard: string;
    doc: string;
    isActive: boolean;
  }) => {
    if (filePath) {
      await post(`${parsed.id}`, { ...values, doc: filePath })
        .then(async (res) => {
          if (res.success) {
            navigate(-1);
          } else {
            alert(res.message);
          }
        })
        .catch((err) => {
          alert("Something went wrong");
          console.log(err);
        });
    } else {
      alert("file not available");
    }
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Study Material</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    description: Yup.string(),
                    subject: Yup.string().required(),
                    standard: Yup.string().required(),
                    doc: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  key={key}
                  enableReinitialize
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="8"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>Description </Form.Label>
                          <Form.Control
                            type="text"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            isInvalid={!!errors.description}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>Select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>Select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            File <span style={{ color: "red" }}>*</span>{" "}
                            {isUploading && <Spinner size="sm" />}
                          </Form.Label>
                          <Form.Control
                            type="file"
                            name="doc"
                            value={values.doc}
                            onChange={handleFileUpload}
                            isValid={touched.doc && !errors.doc}
                            isInvalid={!!errors.doc}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.doc}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  return <div></div>;
};

export default StudyMaterial;
