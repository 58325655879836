import { FC, ReactNode, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CustomHeader from "./CustomHeader";
import CustomNavbar from "./CustomNavbar";

const AppProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const userAuth = localStorage.getItem("userAuth");

    if (userAuth) {
      const { isAuth } = JSON.parse(userAuth as string);

      if (!isAuth) {
        navigate("/login");
      }
    }
  }, []);

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />

      <CustomHeader />
      <CustomNavbar />
      {children}
    </div>
  );
};

export default AppProvider;
