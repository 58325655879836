// import AppProvider from "../components/AppProvider";

// const Dashboard = () => {
//   return (
//     <div>
//       <AppProvider>
//         <div
//           style={{
//             margin: 0,
//             overflow: "hidden",
//             height: "80vh",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             position: "relative",
//             backgroundColor: "#fdf5e6",
//           }}
//         >
//           <h1 style={{ fontWeight: "700" }}>Welcome user</h1>
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

// export default Dashboard;
import React, { useState, useEffect } from "react";
import AppProvider from "../components/AppProvider";
import {
  Card,
  Container,
  Row,
  Col,
  Table,
  ProgressBar,
  Navbar,
  Form,
  FormControl,
  Button,
  Image,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/d.css";

import {
  GetAllAppointmentRoot,
  GetAllLecturesRoot,
  GetAllTestRoot,
} from "../utils/types";
import { useFetch } from "use-http";
import { useAppSelector } from "../store/hooks"; // Import the state hook

const Dashboard: React.FC = () => {
  const branchId = useAppSelector((state) => state.branchReducer._id);

  const {
    get: getTest,
    response: testResponse,
    loading: testLoading,
  } = useFetch<GetAllTestRoot>("/test");
  const {
    get: getLecture,
    response: lectureResponse,
    loading: lectureLoading,
  } = useFetch<GetAllLecturesRoot>("/lecture");
  const {
    get: getAppointments,
    response: appointmentsResponse,
    loading: appointmentsLoading,
  } = useFetch<GetAllAppointmentRoot>("/appointment");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);
      await getTest()
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => console.log(err));
      await getLecture()
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => console.log(err));
      await getAppointments()
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [getTest, getLecture, getAppointments, branchId]);

  if (!testResponse.ok) {
    return <div>...</div>;
  }
  // Filtered data based on branchId
  const filteredTestCount =
    testResponse.data?.data?.filter(
      (item) => !branchId || item.branch._id === branchId
    ).length || 0;

  const filteredLectureCount =
    lectureResponse.data?.data?.filter(
      (item) => !branchId || item.branch._id === branchId
    ).length || 0;

  // const filteredAppointmentsCount =
  //   appointmentsResponse.data?.data?.filter(
  //     (item) => !branchId || item.branch._id === branchId
  //   ).length || 0;

  return (
    <div>
      <AppProvider>
        <Container className="dashboard-container-fluid">
          <Row className="mt-2">
            <h2> Dashboard</h2>
          </Row>
          <Row className="mt-4">
            <Col md={4}>
              <Card
                className="text-black mb-3 Card-animate"
                style={{ width: "400px", height: "160px" }}
              >
                <div
                  className="position-absolute start-0"
                  style={{ zIndex: 0 }}
                >
                  <svg
                    version="1.2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 120"
                    width="200"
                    height="160"
                  >
                    <path
                      id="Shape 8"
                      className="s0"
                      d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                    ></path>
                  </svg>
                </div>
                <Card.Body>
                  <Card.Title>
                    <i className="bi bi-person bg-warning-subtle"></i> Test
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "35px",
                      fontWeight: "bold",
                      color: "rgb(63, 88, 98)",
                    }}
                  >
                    {filteredTestCount}
                  </Card.Text>

                  {/* <Card.Text>{testResponse.data?.data?.length || 0}</Card.Text> */}
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                className="text-black mb-3 Card-animate"
                style={{ width: "400px", height: "160px" }}
              >
                <div
                  className="position-absolute start-0"
                  style={{ zIndex: 0 }}
                >
                  <svg
                    version="1.2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 120"
                    width="200"
                    height="160"
                  >
                    <path
                      id="Shape 8"
                      className="s0"
                      d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                    ></path>
                  </svg>
                </div>
                <Card.Body>
                  <Card.Title>
                    <i className="bi bi-calendar-event bg-warning-subtle"></i>{" "}
                    Lecture
                  </Card.Title>
                  <Card.Text
                    style={{
                      fontSize: "35px",
                      fontWeight: "bold",
                      color: "rgb(63, 88, 98)",
                    }}
                  >
                    {filteredLectureCount}
                  </Card.Text>

                  {/* <Card.Text>
                    {lectureResponse.data?.data?.length || 0}
                  </Card.Text> */}
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card
                className="text-black mb-3 Card-animate"
                style={{ width: "400px", height: "160px" }}
              >
                <div
                  className="position-absolute start-0"
                  style={{ zIndex: 0 }}
                >
                  <svg
                    version="1.2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 120"
                    width="200"
                    height="160"
                  >
                    <path
                      id="Shape 8"
                      className="s0"
                      d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z"
                    ></path>
                  </svg>
                </div>
                <Card.Body>
                  <Card.Title>
                    <i className="bi bi-card-checklist bg-warning-subtle"></i>{" "}
                    Appoinments
                  </Card.Title>
                  {/* <Card.Text>{filteredAppointmentsCount}</Card.Text> */}

                  <Card.Text
                    style={{
                      fontSize: "35px",
                      fontWeight: "bold",
                      color: "rgb(63, 88, 98)",
                    }}
                  >
                    {appointmentsResponse.data?.data?.length || 0}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </AppProvider>
    </div>
  );
};

export default Dashboard;
