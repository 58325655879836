import Lottie from "lottie-react";
import notFound from "../assets/404.json";

const ErrorPage = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#12102d",
      }}
    >
      <Lottie
        animationData={notFound}
        loop={true}
        style={{
          width: "95%",
        }}
      />
    </div>
  );
};

export default ErrorPage;
