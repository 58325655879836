import React, { useEffect, useState } from "react";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "use-http";
import {
  AddDesignationRoot,
  GetAllDesignationAPIRoot,
  GetAllLecturesDaum,
  GetAllLecturesRoot,
  // GetAllTestDaum,
  // GetAllTestRoot,
  GetByIdStudentRoot,
} from "../utils/types";
import Swal from "sweetalert2";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { useAppSelector } from "../store/hooks";
import ReactPaginate from "react-paginate";

const Test = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

interface Branch {
  _id: string;
  name: string;
}

interface Teacher {
  _id: string;
  name: string;
}

interface Subject {
  name: string;
}

interface Batch {
  name: string;
}

interface Standard {
  name: string;
}

interface GetAllTestDaum {
  _id: string;
  name: string;
  totalMarks: number;
  date: string;
  fromTime: string;
  toTime: string;
  branch: Branch;
  teacher: Teacher;
  subject: Subject;
  batch: Batch;
  standard: Standard;
  isActive: boolean;
}

interface GetAllTestRoot {
  success: boolean;
  data: GetAllTestDaum[];
}

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetAllTestRoot>("/test");
  const [refreshing, setRefreshing] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  // Get teacherId from the auth state
  const teacherId = useAppSelector((state) => state.authReducer._id);
  // Get branchId from the branch state
  const branchId = useAppSelector((state) => state.branchReducer._id);
  const [selectedDate, setSelectedDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [tableData, setTableData] = useState<GetAllTestDaum[]>([]); // for table data //

  //  when there is no data on screen then user only appear spinner//

  useEffect(() => {
    (async () => {
      setRefreshing(true);
      await fetchData(selectedDate);
    })();
  }, [branchId]);

  const fetchData = async (date: string) => {
    setRefreshing(true);
    try {
      const res = await get();
      if (res && res.success) {
        setTableData(res.data || []);
      } else {
        setTableData([]);
      }
    } catch (err) {
      console.log(err);
      setTableData([]);
    }
    setRefreshing(false);
  };
  const handlePageChange = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
    fetchData(event.target.value);
    // fetchData(newDate);
  };
  // const isTestCompleted = (toTime: string, date: string) => {
  //   const now = moment();
  //   const lectureDate = moment(date, "YYYY-MM-DD");
  //   const endTime = moment(toTime, "HH:mm");

  //   return (
  //     lectureDate.isBefore(now, "day") ||
  //     (lectureDate.isSame(now, "day") && now.isAfter(endTime))
  //   );
  // };

  const isAttendanceAllowed = (
    fromTime: string,
    toTime: string,
    date: string
  ) => {
    const now = moment();
    const startTime = moment(
      `${date} ${fromTime}`,
      "YYYY-MM-DD HH:mm"
    ).subtract(10, "minutes");
    const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
      10,
      "minutes"
    );
    return now.isBetween(startTime, endTime);
  };

  const isTestCompleted = (fromTime: string, toTime: string, date: string) => {
    const now = moment();
    const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
      10,
      "minutes"
    );
    return now.isAfter(endTime);
  };

  const filteredData = tableData.filter(
    (item: GetAllTestDaum) =>
      item.teacher._id === teacherId &&
      moment(item.date).format("YYYY-MM-DD") === selectedDate &&
      (!branchId || item.branch._id === branchId)
  );
  // =============================================================for empty data table when we are not click on branch selection===========================================

  // useEffect(() => {
  //   (async () => {
  //     setRefreshing(true);

  //     await get(`&branchId=${branchId}`)
  //       .then((res) => {
  //         if (res && res.success) {
  //           setTableData(res.data || []);
  //           setRefreshing(false);
  //         } else {
  //           setTableData([]);
  //           setRefreshing(false);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setTableData([]);
  //         setRefreshing(false);
  //       });

  //     await fetchData(selectedDate);
  //   })();
  // }, [get, branchId]);

  // const fetchData = async (date: string) => {
  //   setRefreshing(true);

  //   await get(`&branchId=${branchId}&date=${date}`)
  //     .then((res) => {
  //       if (res && res.success) {
  //         setTableData(res.data || []);
  //         setRefreshing(false);
  //       } else {
  //         setTableData([]);
  //         setRefreshing(false);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setTableData([]);
  //       setRefreshing(false);
  //     });
  // };

  // const fetchData = async (date: string) => {
  //   setRefreshing(true);

  //   await get()
  //     .then((res) => {
  //       if (res) {
  //         //@ts-ignore
  //         if (res && res.success) {
  //           setRefreshing(false);
  //           setTableData(res.data || []);
  //         } else {
  //           setRefreshing(false);
  //           setTableData([]);
  //         }
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // const filteredData = tableData
  //   .filter(
  //     (item: GetAllTestDaum) =>
  //       moment(item.date).format("YYYY-MM-DD") === selectedDate
  //   )
  //   .filter((item: GetAllTestDaum) => item.teacher._id === teacherId); // filter by teacherId

  // const filteredData = tableData.filter(
  //   (item: GetAllTestDaum) =>
  //     item.branch._id === branchId &&
  //     moment(item.date).format("YYYY-MM-DD") === selectedDate &&
  //     item.teacher._id === teacherId
  // );

  if (loading || refreshing) {
    return (
      <div>
        <AppProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }
  const currentData = response?.data?.data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Test ({moment(selectedDate).format("DD-MM-YYYY")})</h5>
                  <input
                    type="date"
                    style={{ borderRadius: "6px", padding: "6px" }}
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </div>
              </Card.Header>

              <Card.Body>
                <div className="table-responsive">
                  {" "}
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Test name </th>
                        <th>Total Marks</th>
                        {/* <th>Description</th> */}
                        <th>Date</th>
                        <th>Subject</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Teacher</th>
                        <th>Branch</th>
                        <th>Batch</th>
                        <th>Standard</th>
                        <th>Test Status</th>
                        <th
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* ===============================================for branch selection fillter not teacherId============================ */}
                      {/* {response?.data?.data
                      ?.filter(
                        (x: any) =>
                          x.branch._id === branchId &&
                          moment(x.date).format("YYYY-MM-DD") === selectedDate
                        // &&
                        // x.techer._id === teacherId
                      )

                      .map((item: any, index: number) => ( */}
                      {/* ========================================================================================================================== */}
                      {filteredData.length > 0 ? (
                        filteredData
                          .sort((a, b) => {
                            const timeA = a.fromTime.split(":");
                            const timeB = b.fromTime.split(":");
                            return (
                              parseInt(timeA[0]) * 60 +
                              parseInt(timeA[1]) -
                              (parseInt(timeB[0]) * 60 + parseInt(timeB[1]))
                            );
                          })
                          .map((item, index) => {
                            const isPending = !isTestCompleted(
                              item.fromTime,
                              item.toTime,
                              item.date
                            );
                            const attendanceAllowed = isAttendanceAllowed(
                              item.fromTime,
                              item.toTime,
                              item.date
                            );
                            return (
                              <tr key={item._id}>
                                <td>{index + 1}</td>

                                <td>{item.name || ""}</td>
                                <td>{item.totalMarks || ""}</td>
                                <td>
                                  {moment(item.date).format("DD-MM-YYYY") || ""}
                                </td>
                                <td>{item.subject.name || ""}</td>
                                <td>{item.fromTime || ""}</td>
                                <td>{item.toTime || ""}</td>
                                <td>{item.teacher.name || ""}</td>
                                <td>{item.branch.name || ""}</td>
                                <td>{item.batch.name || ""}</td>
                                <td>{item.standard.name || ""}</td>
                                {/* <td>{item.isActive ? "Yes" : "No"}</td> */}
                                {/* <td>
                                   {isTestCompleted(item.toTime, item.date) ? (
                                     <Badge bg="success">Completed</Badge>
                                   ) : (
                                     <Badge bg="danger">Pending</Badge>
                                   )}
                                 </td> */}
                                <td>
                                  {isPending ? (
                                    <Badge bg="danger">Pending</Badge>
                                  ) : (
                                    <Badge bg="success">Completed</Badge>
                                  )}
                                </td>
                                <td
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div style={{ display: "flex", gap: "1rem" }}>
                                    <Button
                                      variant={
                                        attendanceAllowed ? "danger" : "primary"
                                      }
                                      onClick={() =>
                                        navigate(`/testAt/${item._id}`)
                                      }
                                      // disabled={
                                      //   !isAttendanceAllowed(
                                      //     item.fromTime,
                                      //     item.toTime,
                                      //     item.date
                                      //   )
                                      // }
                                    >
                                      Attendence
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={13} style={{ textAlign: "center" }}>
                            No data available for the selected date and branch
                          </td>
                        </tr>
                      )}
                      {/* // ))} */}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const navigate = useNavigate();
  const { post } = useFetch<GetAllTestRoot>("/test");
  const {
    get: getSubject,
    response: subjectResponse,
    loading: subjectLoading,
    error: subjectError,
  } = useFetch<GetAllDesignationAPIRoot>("/subject");
  const {
    get: getBranch,
    response: branchResponse,
    loading: branchLoading,
    error: branchError,
  } = useFetch<GetAllDesignationAPIRoot>("/branch");
  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const {
    get: getTeacher,
    response: teacherResponse,
    loading: teacherLoading,
    error: teacherError,
  } = useFetch<GetAllDesignationAPIRoot>("/teacher");
  const {
    get: getStandard,
    response: standardResponse,
    loading: standardLoading,
    error: standardError,
  } = useFetch<GetAllDesignationAPIRoot>("/standard");
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getBatch();

        await getBranch();

        await getStandard();

        await getSubject();

        await getTeacher();
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: {
    name: string;
    description: string;
    totalMarks: string;
    standard: string;
    subject: string;
    teacher: string;
    batch: string;
    branch: string;
    date: string;
    fromTime: string;
    toTime: string;
    isActive: boolean;
  }) => {
    try {
      const res = await post(values);
      if (res.success) {
        // Ensure to access the 'success' property correctly
        alert("Test added successfully!");
        navigate(-1);
      } else {
        alert("Failed to add test. Please try again."); // Handle error cases
      }
    } catch (err) {
      alert("Error adding test"); // Handle network or other errors
      console.log(err);
    }
  };

  // const handleSubmit = async (values: { isActive: boolean }) => {
  //   await post(values)
  //     .then(async (res) => {
  //       if (res.success) {
  //         navigate(-1);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };
  // const handleSubmit = async (values: {
  //   name: string;
  //   description: string;
  //   totalMarks: string;
  //   date: string;
  //   fromTime: string;
  //   toTime: string;
  //   isActive: boolean;
  // }) => {
  //   try {
  //     const res = await post(values);
  //     if (res.success) {
  //       alert("Test added successfully!");
  //       navigate(-1);
  //     } else {
  //       alert(res.message);
  //     }
  //   } catch (err) {
  //     alert("Error adding test");
  //     console.log(err);
  //   }
  // };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Test</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    subject: Yup.string().required("subject is required"),
                    branch: Yup.string().required(),
                    batch: Yup.string().required(),
                    teacher: Yup.string().required(),
                    standard: Yup.string().required(),
                    name: Yup.string().required(),
                    description: Yup.string().required(),
                    totalMarks: Yup.string().required(),

                    fromTime: Yup.string().required(),
                    toTime: Yup.string().required(),
                    date: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  initialValues={{
                    subject: "",
                    branch: "",
                    batch: "",
                    teacher: "",
                    standard: "",
                    name: "",
                    description: "",
                    totalMarks: "",
                    fromTime: "",
                    toTime: "",
                    date: "",
                    isActive: true,
                  }}
                  onSubmit={(values, { setSubmitting, setErrors }) => {
                    handleSubmit(values)
                      .catch((error) => {
                        alert("Validation errors: " + error);
                        setErrors(error);
                      })
                      .finally(() => setSubmitting(false));
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Branch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.branch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Teacher <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="teacher"
                            onChange={handleChange}
                            value={values.teacher}
                            isInvalid={!!touched.teacher && !!errors.teacher}
                          >
                            <option value={""}>select</option>

                            {teacherResponse &&
                              teacherResponse.data &&
                              teacherResponse.data?.data &&
                              teacherResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.teacher}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Test name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Description <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            isValid={touched.description && !errors.description}
                            isInvalid={!!errors.description}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Total Marks <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="totalMarks"
                            value={values.totalMarks}
                            onChange={handleChange}
                            isValid={touched.totalMarks && !errors.totalMarks}
                            isInvalid={!!errors.totalMarks}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.totalMarks}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            From time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="fromTime"
                            value={values.fromTime}
                            onChange={handleChange}
                            isValid={touched.fromTime && !errors.fromTime}
                            isInvalid={!!touched.fromTime && !!errors.fromTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fromTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik07"
                          className="mb-3"
                        >
                          <Form.Label>
                            To time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="toTime"
                            value={values.toTime}
                            onChange={handleChange}
                            isValid={touched.toTime && !errors.toTime}
                            isInvalid={!!touched.toTime && !!errors.toTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.toTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date}
                            isInvalid={!!touched.date && !!errors.date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post } = useFetch("/test");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    name: "",
    totalMarks: "",
    description: "",
    branch: "",
    batch: "",
    standard: "",
    subject: "",
    teacher: "",
    fromTime: "",
    toTime: "",
    date: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());
  const {
    get: getBranch,
    response: branchResponse,
    loading: branchLoading,
    error: branchError,
  } = useFetch<GetAllDesignationAPIRoot>("/branch");
  const {
    get: getStandard,
    response: standardResponse,
    loading: standardLoading,
    error: standardError,
  } = useFetch<GetAllDesignationAPIRoot>("/standard");
  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const {
    get: getSubject,
    response: subjectResponse,
    loading: subjectLoading,
    error: subjectError,
  } = useFetch<GetAllDesignationAPIRoot>("/subject");
  const {
    get: getTeacher,
    response: teacherResponse,
    loading: teacherLoading,
    error: teacherError,
  } = useFetch<GetAllDesignationAPIRoot>("/teacher");

  useEffect(() => {
    (async () => {
      await getStandard();
      await getBranch();
      await getBatch();
      await getSubject();
      await getTeacher();

      await get(`${parsed.id}`)
        .then((res) => {
          if (res.success) {
            console.log(res);

            setInitValues({
              isActive: res.data?.isActive,
              name: res.data?.name || " ",
              totalMarks: res.data?.totalMarks || " ",
              description: res.data?.description || " ",
              branch: res.data?.branch?._id || " ",
              batch: res.data?.batch?._id || "",
              standard: res.data?.standard?._id || "",
              subject: res.data?.subject?._id || "",
              teacher: res.data?.teacher?._id || "",
              fromTime: res.data?.fromTime || "",
              toTime: res.data?.toTime || "",
              date: res.data?.date || "",
            });

            setKey(Math.random());
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [parsed.id, get]);

  const handleSubmit = async (values: {
    name: string;
    totalMarks: string;
    description: string;
    branch: string;
    batch: string;
    standard: string;
    subject: string;
    teacher: string;
    fromTime: string;
    toTime: string;
    date: string;
    isActive: boolean;
  }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Lecture</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    totalMarks: Yup.string().required(),
                    description: Yup.string().required(),
                    standard: Yup.string().required(),
                    branch: Yup.string().required(),
                    batch: Yup.string().required(),
                    subject: Yup.string().required(),
                    teacher: Yup.string().required(),
                    fromTime: Yup.string().required(),
                    toTime: Yup.string().required(),
                    date: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Test Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Total Marks <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="totalMarks"
                            value={values.totalMarks}
                            onChange={handleChange}
                            isValid={touched.totalMarks && !errors.totalMarks}
                            isInvalid={!!errors.totalMarks}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.totalMarks}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Description <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            isValid={touched.description && !errors.description}
                            isInvalid={!!errors.description}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.description}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Branch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.branch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Teacher <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="teacher"
                            onChange={handleChange}
                            value={values.teacher}
                            isInvalid={!!touched.teacher && !!errors.teacher}
                          >
                            <option value={""}>select</option>

                            {teacherResponse &&
                              teacherResponse.data &&
                              teacherResponse.data?.data &&
                              teacherResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.teacher}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            From time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="fromTime"
                            value={values.fromTime}
                            onChange={handleChange}
                            isValid={touched.fromTime && !errors.fromTime}
                            isInvalid={!!touched.fromTime && !!errors.fromTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fromTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            To time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="toTime"
                            value={values.toTime}
                            onChange={handleChange}
                            isValid={touched.toTime && !errors.toTime}
                            isInvalid={!!touched.toTime && !!errors.toTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.toTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date}
                            isInvalid={!!touched.date && !!errors.date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};
const View = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get, response, loading, error } =
    useFetch<GetByIdStudentRoot>("/lecture");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);
      if (parsed.id) {
        await get(`${parsed.id}`)
          .then((res) => {
            if (res) {
              if (res.success) {
                setRefreshing(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setRefreshing(false);
          });
      }
    })();
  }, [parsed.id, get]);

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>View Lecture</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  {response && response.ok && response.data && (
                    <Table bordered hover>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{response.data?.data.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Username</td>
                          <td>{response.data?.data.username || ""}</td>
                        </tr>

                        <tr>
                          <td>Is Active</td>
                          <td>{response.data?.data.isActive ? "Yes" : "No"}</td>
                        </tr>

                        <tr>
                          <td>Created At</td>
                          <td>
                            {moment(response.data?.data.createdAt).format(
                              "DD-MM-YYYY H:mm A"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default Test;
