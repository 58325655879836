import { Formik } from "formik";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useFetch } from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import {
  AddDesignationRoot,
  GetAllAppointmentDaum,
  GetAllAppointmentRoot,
  GetAllDesignationAPIRoot,
  GetAppointmentByIdRoot,
} from "../utils/types";
import { useAppSelector } from "../store/hooks";

const Appointment = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<GetAllAppointmentRoot>("/appointment");
  const [refreshing, setRefreshing] = useState(true);
  // Get teacherId from the auth state
  const teacherId = useAppSelector((state) => state.authReducer._id);
  // Get branchId from the branch state
  const branchId = useAppSelector((state) => state.branchReducer._id);
  const [selectedDate, setSelectedDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );
  const [tableData, setTableData] = useState<GetAllAppointmentDaum[]>([]); // for table data //
  //   useEffect(() => {
  //     (async () => {
  //       setRefreshing(true);

  //       await get()
  //         .then((res) => {
  //           if (res) {
  //             if (res.success) {
  //               setRefreshing(false);
  //             }
  //           }
  //         })
  //         .catch((err) => console.log(err));
  //     })();
  //   }, [get]);
  useEffect(() => {
    (async () => {
      setRefreshing(true);
      await fetchData(selectedDate);
    })();
  }, [branchId]);

  //   const getData = async () => {
  //     setRefreshing(true);

  //     await get()
  //       .then((res) => {
  //         if (res) {
  //           if (res.success) {
  //             setRefreshing(false);
  //           }
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   };
  const fetchData = async (date: string) => {
    setRefreshing(true);
    try {
      const res = await get();
      if (res && res.success) {
        setTableData(res.data || []);
      } else {
        setTableData([]);
      }
    } catch (err) {
      console.log(err);
      setTableData([]);
    }
    setRefreshing(false);
  };

  //   const handleDelete = async (_id: string) => {
  //     setRefreshing(true);

  //     await deleteDesignation(`${_id}`).then((res) => {
  //       if (res.success) {
  //         Swal.fire({
  //           title: "Deleted!",
  //           text: "Your data has been deleted.",
  //           icon: "success",
  //         });
  //       }
  //     });
  //     await getData();

  //     setRefreshing(false);
  //   };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
    fetchData(event.target.value);
    // fetchData(newDate);
  };
  //   const filteredData = tableData.filter(
  //     (item: GetAllAppointmentDaum) =>
  //       item.teacher._id === teacherId &&
  //       moment(item.date).format("YYYY-MM-DD") === selectedDate &&
  //       (!branchId || item.branch._id === branchId)
  //   );
  const filteredData = tableData.filter(
    (item: GetAllAppointmentDaum) =>
      //   item.branch._id === branchId &&
      moment(item.date).format("YYYY-MM-DD") === selectedDate &&
      item.teacher._id === teacherId
  );
  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>
                    Appointment({moment(selectedDate).format("DD-MM-YYYY")})
                  </h5>
                  <input
                    type="date"
                    style={{ borderRadius: "6px", padding: "6px" }}
                    value={selectedDate}
                    onChange={handleDateChange}
                  />

                  {/* {true && (
                    <Button onClick={() => navigate("/appointment?action=add")}>
                      ADD
                    </Button>
                  )} */}
                </div>
              </Card.Header>

              <Card.Body>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Parent</th>
                      <th>Teacher</th>
                      <th>Type</th>
                      <th>Time</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {response &&
                      response.ok &&
                      response.data &&
                      response?.data?.data.map((item, index) => { */}
                    {/* return ( */}
                    {filteredData.length > 0 ? (
                      filteredData.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>
                          <td>{moment(item.date).format("DD/MM/YYYY")}</td>
                          <td>{item.parent.name || ""}</td>
                          <td>{item.teacher.name || ""}</td>
                          <td>{item.appointmentType.name || ""}</td>
                          <td>{item.timeSlot.name || ""}</td>
                          <td>{item.isActive ? "Yes" : "No"}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                gap: "1rem",
                              }}
                            >
                              <Button
                                onClick={() =>
                                  navigate(
                                    `/appointment?action=view&&id=${item._id}`
                                  )
                                }
                              >
                                View
                              </Button>
                              {/* <Button
                                  variant="danger"
                                  onClick={() => {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, delete it!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleDelete(item._id);
                                      }
                                    });
                                  }}
                                >
                                  DELETE
                                </Button> */}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan={13}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          No data available for the selected date and branch
                        </td>
                      </tr>
                    )}
                    {/* ); */}
                    {/* //   })} */}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Add = () => {
  const [refreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();
  const { post } = useFetch<AddDesignationRoot>("/appointment");
  const {
    get: getParent,
    response: parentResponse,
    loading: parentLoading,
    error: parentError,
  } = useFetch<GetAllDesignationAPIRoot>("/parent");
  const {
    get: getTeacher,
    response: teacherResponse,
    loading: teacherLoading,
    error: teacherError,
  } = useFetch<GetAllDesignationAPIRoot>("/teacher");
  const {
    get: getAppointmentType,
    response: appointmentTypeResponse,
    loading: appointmentTypeLoading,
    error: appointmentTypeError,
  } = useFetch<GetAllDesignationAPIRoot>("/appointmentType");
  const {
    get: getTimeSlot,
    response: timeSlotResponse,
    loading: timeSlotLoading,
    error: timeSlotError,
  } = useFetch<GetAllDesignationAPIRoot>("/timeSlot");

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getParent();
        await getTeacher();
        await getAppointmentType();
        await getTimeSlot();
      } catch (err) {
        console.log(err);
      }

      setRefreshing(false);
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: { name: string; isActive: boolean }) => {
    await post(values)
      .then(async (res) => {
        if (res.success) {
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Appointment</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    date: Yup.string().required(),
                    teacher: Yup.string().required(),
                    parent: Yup.string().required(),
                    timeSlot: Yup.string().required(),
                    appointmentType: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={{
                    name: "",
                    date: "",
                    teacher: "",
                    parent: "",
                    timeSlot: "",
                    appointmentType: "",
                    isActive: true,
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date}
                            isInvalid={!!errors.date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Parent <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="parent"
                            onChange={handleChange}
                            value={values.parent}
                            isInvalid={!!touched.parent && !!errors.parent}
                          >
                            <option value={""}>Select</option>

                            {parentResponse &&
                              parentResponse.data &&
                              parentResponse.data?.data &&
                              parentResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.parent}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Teacher <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="teacher"
                            onChange={handleChange}
                            value={values.teacher}
                            isInvalid={!!touched.teacher && !!errors.teacher}
                          >
                            <option value={""}>Select</option>

                            {teacherResponse &&
                              teacherResponse.data &&
                              teacherResponse.data?.data &&
                              teacherResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.teacher}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Appoint Type <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="appointmentType"
                            onChange={handleChange}
                            value={values.appointmentType}
                            isInvalid={
                              !!touched.appointmentType &&
                              !!errors.appointmentType
                            }
                          >
                            <option value={""}>Select</option>

                            {appointmentTypeResponse &&
                              appointmentTypeResponse.data &&
                              appointmentTypeResponse.data?.data &&
                              appointmentTypeResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.appointmentType}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Time Slot <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="timeSlot"
                            onChange={handleChange}
                            value={values.timeSlot}
                            isInvalid={!!touched.timeSlot && !!errors.timeSlot}
                          >
                            <option value={""}>Select</option>

                            {timeSlotResponse &&
                              timeSlotResponse.data &&
                              timeSlotResponse.data?.data &&
                              timeSlotResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.timeSlot}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post } = useFetch<GetAppointmentByIdRoot>("/appointment");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [refreshing, setRefreshing] = useState(false);
  const [initValues, setInitValues] = useState({
    name: "",
    date: "",
    teacher: "",
    parent: "",
    timeSlot: "",
    appointmentType: "",
    isActive: true,
  });
  const [key, setKey] = useState(Math.random());
  const {
    get: getParent,
    response: parentResponse,
    loading: parentLoading,
    error: parentError,
  } = useFetch<GetAllDesignationAPIRoot>("/parent");
  const {
    get: getTeacher,
    response: teacherResponse,
    loading: teacherLoading,
    error: teacherError,
  } = useFetch<GetAllDesignationAPIRoot>("/teacher");
  const {
    get: getAppointmentType,
    response: appointmentTypeResponse,
    loading: appointmentTypeLoading,
    error: appointmentTypeError,
  } = useFetch<GetAllDesignationAPIRoot>("/appointmentType");
  const {
    get: getTimeSlot,
    response: timeSlotResponse,
    loading: timeSlotLoading,
    error: timeSlotError,
  } = useFetch<GetAllDesignationAPIRoot>("/timeSlot");

  useEffect(() => {
    const fetchData = async () => {
      setRefreshing(true);

      try {
        await getParent();
        await getTeacher();
        await getAppointmentType();
        await getTimeSlot();

        await get(`${parsed.id}`)
          .then((res) => {
            if (res.success) {
              setInitValues({
                isActive: res.data.isActive,
                name: res.data.name,
                appointmentType: res.data.appointmentType._id,
                date: res.data.date,
                parent: res.data.parent._id,
                teacher: res.data.teacher._id,
                timeSlot: res.data.timeSlot._id,
              });

              setKey(Math.random());
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setRefreshing(false));
      } catch (err) {
        console.log(err);
      } finally {
        setRefreshing(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values: { name: string; isActive: boolean }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  if (refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update appointment</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    date: Yup.string().required(),
                    teacher: Yup.string().required(),
                    parent: Yup.string().required(),
                    timeSlot: Yup.string().required(),
                    appointmentType: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Name <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik01"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date}
                            isInvalid={!!errors.date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik02"
                          className="mb-3"
                        >
                          <Form.Label>
                            Parent <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="parent"
                            onChange={handleChange}
                            value={values.parent}
                            isInvalid={!!touched.parent && !!errors.parent}
                          >
                            <option value={""}>Select</option>

                            {parentResponse &&
                              parentResponse.data &&
                              parentResponse.data?.data &&
                              parentResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.parent}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik03"
                          className="mb-3"
                        >
                          <Form.Label>
                            Teacher <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="teacher"
                            onChange={handleChange}
                            value={values.teacher}
                            isInvalid={!!touched.teacher && !!errors.teacher}
                          >
                            <option value={""}>Select</option>

                            {teacherResponse &&
                              teacherResponse.data &&
                              teacherResponse.data?.data &&
                              teacherResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.teacher}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik04"
                          className="mb-3"
                        >
                          <Form.Label>
                            Appoint Type <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="appointmentType"
                            onChange={handleChange}
                            value={values.appointmentType}
                            isInvalid={
                              !!touched.appointmentType &&
                              !!errors.appointmentType
                            }
                          >
                            <option value={""}>Select</option>

                            {appointmentTypeResponse &&
                              appointmentTypeResponse.data &&
                              appointmentTypeResponse.data?.data &&
                              appointmentTypeResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.appointmentType}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik05"
                          className="mb-3"
                        >
                          <Form.Label>
                            Time Slot <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="timeSlot"
                            onChange={handleChange}
                            value={values.timeSlot}
                            isInvalid={!!touched.timeSlot && !!errors.timeSlot}
                          >
                            <option value={""}>Select</option>

                            {timeSlotResponse &&
                              timeSlotResponse.data &&
                              timeSlotResponse.data?.data &&
                              timeSlotResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.timeSlot}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get, response, loading, error } =
    useFetch<GetAppointmentByIdRoot>("/appointment");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);
      if (parsed.id) {
        await get(`${parsed.id}`)
          .then((res) => {
            if (res) {
              if (res.success) {
                setRefreshing(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setRefreshing(false);
          });
      }
    })();
  }, [parsed.id, get]);

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>View Appointment</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  {response && response.ok && response.data && (
                    <Table bordered hover>
                      <tbody>
                        <tr>
                          <td>Parent Name</td>
                          <td>{response.data?.data.parent.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Teacher Name</td>
                          <td>{response.data?.data.teacher.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Appointment Type</td>
                          <td>
                            {response.data?.data.appointmentType.name || ""}
                          </td>
                        </tr>

                        <tr>
                          <td> Time Slot </td>
                          <td>{response.data?.data.timeSlot.name || ""}</td>
                        </tr>

                        <tr>
                          <td> Date </td>
                          <td>
                            {moment(response.data?.data.date).format(
                              "DD-MM-YYYY "
                            )}
                          </td>{" "}
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default Appointment;
