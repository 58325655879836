import { FC, ReactNode, useEffect } from "react";
import { setAuth } from "../store/authReducer";
import { useAppDispatch } from "../store/hooks";

const StoreProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const userAuth = localStorage.getItem("userAuth");

    if (userAuth) {
      const {
        _id,
        isAdmin,
        isAuth,
        isBranch,
        isCompany,
        isParent,
        isStudent,
        isTeacher,
      } = JSON.parse(userAuth as string);

      if (isAuth) {
        dispatch(
          setAuth({
            _id: _id,
            isAdmin: isAdmin,
            isAuth: isAuth,
            isBranch: isBranch,
            isCompany: isCompany,
            isParent: isParent,
            isStudent: isStudent,
            isTeacher: isTeacher,
          })
        );
      }
    }
  }, []);

  return <div>{children}</div>;
};

export default StoreProvider;
