import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { navbarLinks } from "../utils/routes";

const CustomNavbar = () => {
  return (
    <div>
      <Navbar expand="lg" style={{ backgroundColor: "#f8ad00" }}>
        <Container>
          <Navbar.Brand></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              //className="me-auto"
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <Nav.Link style={{ fontWeight: "700" }} href="/dashboard">
                Dashboard
              </Nav.Link>
              <Nav.Link style={{ fontWeight: "700" }} href="/class">
                Class
              </Nav.Link>
              <Nav.Link style={{ fontWeight: "700" }} href="/test">
                Test
              </Nav.Link>
              <Nav.Link style={{ fontWeight: "700" }} href="/studyMaterial">
                Study Material
              </Nav.Link>
              <Nav.Link style={{ fontWeight: "700" }} href="/appointment">
                Appointment
              </Nav.Link>
              {/* <Nav.Link style={{ fontWeight: "700" }} href="/testPage">
                Test Page
              </Nav.Link> */}

              {/* <Nav.Link style={{ fontWeight: "700" }} href="/attendance">
                Attendence
              </Nav.Link> */}

              {/* {navbarLinks.map((item) => (
                <NavDropdown
                  title={item.name}
                  id="basic-nav-dropdown"
                  renderMenuOnMount={true}
                  style={{ fontWeight: "700" }}
                >
                  {item.children
                    .sort((a, b) => {
                      if (a.name < b.name) {
                        return -1;
                      } else {
                        if (a.name > b.name) {
                          return 1;
                        } else return 0;
                      }
                    })
                    .map((innerItem) => (
                      <NavDropdown.Item>
                        <Link
                          to={innerItem.link}
                          style={{
                            display: "block",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          {innerItem.name}
                        </Link>
                      </NavDropdown.Item>
                    ))}
                </NavDropdown>
              ))} */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
