import { Provider } from "react-redux";
import ErrorProvider from "./scpl/components/ErrorProvider";
import HttpProvider from "./scpl/components/HttpProvider";
import RouteProvider from "./scpl/components/RouteProvider";
import { store } from "./scpl/store/store";

import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import StoreProvider from "./scpl/components/StoreProvider";

const App = () => {
  return (
    <ErrorProvider>
      <Provider store={store}>
        <StoreProvider>
          <HttpProvider>
            <RouteProvider />
          </HttpProvider>
        </StoreProvider>
      </Provider>
    </ErrorProvider>
  );
};

export default App;
