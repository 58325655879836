import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface BranchState {
  _id: string;
}

const initialState: BranchState = {
  _id: "",
};

export const branchSlice = createSlice({
  name: "Branch",
  initialState,
  reducers: {
    setBranch: (state, action: PayloadAction<BranchState>) => {
      state._id = action.payload._id;
    },
  },
});

export const { setBranch } = branchSlice.actions;

export const selectBranch = (state: RootState) => state.branchReducer._id;

export default branchSlice.reducer;
