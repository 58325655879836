import React, { useEffect, useState } from "react";
import { useFetch } from "use-http";
import { Button, Spinner, Table, Row, Col } from "react-bootstrap";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import AppProvider from "../components/AppProvider";

interface Student {
  _id: string;
  name: string;
  isPresent: boolean;
  // obtainedMarks: number;
}

const TestAt = () => {
  const { testId } = useParams();
  const { get, response, post } = useFetch("/test");
  const navigate = useNavigate();
  const [testData, setTestData] = useState<any>(null);
  const [students, setStudents] = useState<Student[]>([]);
  const [attendanceData, setAttendanceData] = useState<Student[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalPresent, setTotalPresent] = useState<number>(0);
  const [totalAbsent, setTotalAbsent] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const result = await get(`/${testId}`);
        if (result.success) {
          setTestData(result.data);
          // setStudents(
          //   result.data.attendanceList.map((student: any) => ({
          //     id: student.name._id,
          //     name: student.name.name,
          //   }))
          // );
          const initialAttendance = result.data.attendanceList.map(
            (student: any) => ({
              _id: student._id,
              name: student.name._id,
              isPresent: student.isPresent,
              // obtainedMarks: student.obtainedMarks || 0, // Default to 0 if obtainedMarks is not available
            })
          );
          setAttendanceData(initialAttendance);
          setStudents(initialAttendance); // Set students state here
          setTotalPresent(
            initialAttendance.filter((student: Student) => student.isPresent)
              .length
          );
          setTotalAbsent(
            initialAttendance.filter((student: Student) => !student.isPresent)
              .length
          );
          setIsSubmitted(
            result.data.attendanceList.every((student: any) =>
              student.hasOwnProperty("isPresent")
            )
          );
        } else {
          setError(result.message || "Failed to fetch lecture details.");
        }
      } catch (error) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [get, testId]);

  // update  handle submit
  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      // Prepare the data to send to the server
      const requestData = {
        name: testId,
        studentAttendance: attendanceData,
        // studentAttendance: attendanceData.map(({ id, name, isPresent }) => ({
        //   _id: id,
        //   name: name,
        //   isPresent: isPresent,
        // })),
      };

      console.log(requestData);

      // return;

      // Make a POST request to save the attendance data
      const result = await post("/attendance", requestData);

      console.log(result);

      if (result.success) {
        setIsSubmitted(true);
        // Retrieve the updated attendance data from the server
        const updatedResult = await get(`/${testId}`);
        if (updatedResult.success) {
          const updatedAttendance = updatedResult.data.attendanceList.map(
            (student: any) => ({
              id: student._id,
              name: student.name._id,
              isPresent: student.isPresent,
            })
          );
          setAttendanceData(updatedAttendance);
          setTotalPresent(
            updatedAttendance.filter((student: Student) => student.isPresent)
              .length
          );
          setTotalAbsent(
            updatedAttendance.filter((student: Student) => !student.isPresent)
              .length
          );
        } else {
          setError(
            updatedResult.message || "Failed to fetch updated attendance data."
          );
        }
      } else {
        setError(result.message || "Failed to submit attendance.");
      }
    } catch (error) {
      setError("An error occurred while submitting attendance.");
    } finally {
      setLoading(false);
    }
  };

  // const handleSubmit = async () => {
  //   setLoading(true);
  //   setError(null);
  //   try {
  //     const result = await post("/attendance", {
  //       lecture: testId,
  //       studentAttendance: attendanceData.map(({ id, isPresent }) => ({
  //         name: id,
  //         isPresent,
  //       })),
  //     });
  //     if (result.success) {
  //       setIsSubmitted(true);
  //       const updatedResult = await get(/${testId});
  //       if (updatedResult.success) {
  //         const updatedAttendance = updatedResult.data.attendanceList.map(
  //           (student: any) => ({
  //             id: student.name._id,
  //             name: student.name.name,
  //             isPresent: student.isPresent,
  //             // obtainedMarks: student.obtainedMarks || 0, // Default to 0 if obtainedMarks is not available
  //           })
  //         );
  //         setAttendanceData(updatedAttendance);
  //         setTotalPresent(
  //           updatedAttendance.filter((student: Student) => student.isPresent)
  //             .length
  //         );
  //         setTotalAbsent(
  //           updatedAttendance.filter((student: Student) => !student.isPresent)
  //             .length
  //         );
  //       } else {
  //         setError(
  //           updatedResult.message || "Failed to fetch updated attendance data."
  //         );
  //       }
  //     } else {
  //       setError(result.message || "Failed to submit attendance.");
  //     }
  //   } catch (error) {
  //     setError("An error occurred while submitting attendance.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const handleAttendanceChange = (studentId: string, isPresent: boolean) => {
  //   setAttendanceData((prevData) =>
  //     prevData.map((student) =>
  //       student.id === studentId ? { ...student, isPresent } : student
  //     )
  //   );
  // };

  // const handleObtainedMarksChange = (
  //   studentId: string,
  //   obtainedMarks: number
  // ) => {
  //   setAttendanceData((prevData) =>
  //     prevData.map((student) =>
  //       student.id === studentId ? { ...student, obtainedMarks } : student
  //     )
  //   );
  // };
  const handleAttendanceChange = async (
    studentId: string,
    isPresent: boolean
  ) => {
    console.log("???", isPresent, studentId);

    const updatedAttendance = attendanceData.map((student) =>
      student.name === studentId ? { ...student, isPresent } : student
    );

    console.log(updatedAttendance);
    setAttendanceData(updatedAttendance);
    setTotalPresent(
      updatedAttendance.filter((student) => student.isPresent).length
    );
    setTotalAbsent(
      updatedAttendance.filter((student) => !student.isPresent).length
    );
    try {
      // await post("/attendance", {
      //   lecture: testId,
      //   studentAttendance: updatedAttendance.map(({ id, isPresent }) => ({
      //     name: id,
      //     isPresent,
      //   })),
      // });
    } catch (error) {
      setError("An error occurred while updating attendance.");
    }
  };
  // const isAttendanceAllowed = (
  //   fromTime: string,
  //   toTime: string,
  //   date: string
  // ) => {
  //   const now = moment();
  //   const startTime = moment(`${date} ${fromTime}`, "YYYY-MM-DD HH:mm");
  //   const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm");
  //   return now.isBetween(startTime, endTime);
  //   // const endTime = moment(${date} ${toTime}, "YYYY-MM-DD HH:mm");
  //   // return now.isBefore(endTime.add(20, "minutes")); // Allow attendance up to 20 minutes after test end time
  // };
  const isAttendanceAllowed = (
    fromTime: string,
    toTime: string,
    date: string
  ) => {
    const now = moment();
    const startTime = moment(
      `${date} ${fromTime}`,
      "YYYY-MM-DD HH:mm"
    ).subtract(10, "minutes");
    const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
      10,
      "minutes"
    );
    return now.isBetween(startTime, endTime);
  };

  return (
    <div>
      <AppProvider>
        <h5 style={{ textAlign: "center", marginTop: "10px" }}>
          Today's Test Attendance Report
        </h5>
        <div className="container">
          {loading ? (
            <Spinner animation="border" />
          ) : testData ? (
            <>
              <h5 className="mt-4">Test Details</h5>
              <Table striped bordered hover className="details-table">
                <tbody>
                  <tr>
                    <td>
                      <strong>Name</strong>
                    </td>
                    <td>{testData.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total Marks</strong>
                    </td>
                    <td>{testData.totalMarks}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Subject</strong>
                    </td>
                    <td>{testData.subject.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Standard</strong>
                    </td>
                    <td>{testData.standard.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Branch</strong>
                    </td>
                    <td>{testData.branch.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Batch</strong>
                    </td>
                    <td>{testData.batch.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>From Time</strong>
                    </td>
                    <td>{testData.fromTime}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>To Time</strong>
                    </td>
                    <td>{testData.toTime}</td>
                  </tr>
                </tbody>
              </Table>

              <h5 className="mt-4">Attendance List</h5>
              <Table striped bordered hover className="attendance-table">
                <thead style={{ fontWeight: "bold" }}>
                  <tr>
                    <th>Name</th>
                    <th>Attendance</th>
                    {/* <th>Obtained Marks</th> */}
                  </tr>
                </thead>

                <tbody>
                  {response.data?.data?.attendanceList?.map((student: any) => (
                    <tr key={student._id}>
                      <td>{student.name?.name}</td>
                      <td>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`attendance-${student.name.name}`}
                            checked={
                              attendanceData.find(
                                (x) => x.name === student.name._id
                              )?.isPresent
                            }
                            // checked={student.isPresent}
                            onChange={() =>
                              handleAttendanceChange(student.name._id, true)
                            }
                          />
                          <label className="form-check-label">Present</label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`attendance-${student.name.name}`}
                            // checked={!student.isPresent}
                            checked={
                              !attendanceData.find(
                                (x) => x.name === student.name._id
                              )?.isPresent
                            }
                            onChange={() =>
                              handleAttendanceChange(student.name._id, false)
                            }
                          />
                          <label className="form-check-label">Absent</label>
                        </div>
                      </td>
                      {/* <td>
                        <input
                          type="number"
                          value={student.obtainedMarks}
                          onChange={(e) => {
                            const obtainedMarks = parseInt(e.target.value);
                            if (!isNaN(obtainedMarks)) {
                              handleObtainedMarksChange(
                                student.id,
                                obtainedMarks
                              );
                            }
                          }}
                        />
                      </td> */}
                    </tr>
                  ))}
                </tbody>

                {false && (
                  <tbody>
                    {attendanceData.map((student) => (
                      <tr key={student._id}>
                        <td>{student.name}</td>
                        <td>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`attendance-${student.name}`}
                              checked={
                                attendanceData.find(
                                  (x) => x._id === student.name
                                )?.isPresent
                              }
                              // checked={student.isPresent}
                              onChange={() =>
                                handleAttendanceChange(student.name, true)
                              }
                            />
                            <label className="form-check-label">Present</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`attendance-${student.name}`}
                              // checked={!student.isPresent}
                              checked={
                                !attendanceData.find(
                                  (x) => x._id === student.name
                                )?.isPresent
                              }
                              onChange={() =>
                                handleAttendanceChange(student.name, false)
                              }
                            />
                            <label className="form-check-label">Absent</label>
                          </div>
                        </td>
                        {/* <td>
                        <input
                          type="number"
                          value={student.obtainedMarks}
                          onChange={(e) => {
                            const obtainedMarks = parseInt(e.target.value);
                            if (!isNaN(obtainedMarks)) {
                              handleObtainedMarksChange(
                                student.id,
                                obtainedMarks
                              );
                            }
                          }}
                        />
                      </td> */}
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
              <Row className="mt-4">
                <Col>
                  {isAttendanceAllowed(
                    testData.fromTime,
                    testData.toTime,
                    testData.date
                  ) && <Button onClick={handleSubmit}>Submit</Button>}
                </Col>
              </Row>

              <Table striped bordered hover className="summary-table mt-4">
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Students</td>
                    <td>{attendanceData.length}</td>
                  </tr>
                  <tr>
                    <td>Present</td>
                    <td style={{ color: "green", fontWeight: "bolder" }}>
                      {totalPresent}
                    </td>
                  </tr>
                  <tr>
                    <td>Absent</td>
                    <td style={{ color: "red", fontWeight: "bolder" }}>
                      {totalAbsent}
                    </td>
                  </tr>
                  <tr>
                    {/* <td>Obtained Marks</td>
                    <td>
                      {attendanceData.reduce(
                        (totalMarks, student) =>
                          totalMarks + student.obtainedMarks,
                        0
                      )}
                    </td> */}
                  </tr>
                </tbody>
              </Table>
            </>
          ) : null}
        </div>
      </AppProvider>
    </div>
  );
};

export default TestAt;
